import { AxiosError } from 'axios';
import { IcusomerDetailsPayload } from 'interface/customerInterface';
import { ITransaction } from 'interface/transactionInterface';
import { showNotification } from 'helper/common/commonFunctions';
import { IErrorResponse } from 'interface/apiInterface';
import { ISearchStore } from 'interface/storeInterface';

export const loginEP = '/auth/email_token';
export const loginVerifyEP = '/auth/validate_email_token';
export const refreshTokenEP = '/auth/refresh_token';
export const transactionsEP = '/transactions/update';
export const transactionSearchEP = '/transactions/search';
export const transactionDisputeEP = '/transactions/dispute';
export const transactionChargeDisputeEP = '/transactions/charge/dispute';
export const transactionChargeChargebackEP = '/transactions/charge/chargeback';
export const transactionExportEP = '/transactions/export';
export const transactionLog = '/transactions-events/search';
export const transactionVoidEP = '/transactions/void';
export const transactionRefundEP = '/transactions/refund';
export const searchCustomerEP = '/customers/search';
export const exportCustomerEP = '/customers/export';
export const updateCustomerEP = '/customers/update';
export const subscriptionStatsEP = '/subscriptions/stats';
export const transactionStatsEP = '/transactions/stats';
export const searchOrderEP = '/orders/search';
export const cartListEP = '/carts/list';
export const postNotesEP = '/notes';
export const updateNotesEP = '/notes/update';
export const deletesNotesEp = 'store/notes/remove';
export const registerUserEP = '/users/register';
export const createStoreEP = '/stores/create';
export const updateStoreEP = '/stores/update';
export const createStoreFrontEP = '/storefronts/create';
export const updateStoreFrontEP = '/storefronts/update';
export const createLegacyPaymentGatewayEP = '/stores/payment-gateway/create';
export const createPaymentGatewayEP = '/stores/payment-gateway/create/v2';
export const updateLegacyPaymentGatewayEP = '/stores/payment-gateway/update';
export const updatePaymentGatewayEP = '/stores/payment-gateway/update/v2';
export const getUsersPermissionEP = '/my/permissions';
export const getAllUsersEP = '/admin/all-users';
export const createAdminUsersEP = 'admin/invite';
export const updateAdminUsersEP = 'admin/permissions';
export const sendInviteEP = '/users/invite';
export const reSendInviteEP = '/users/invite/resend';
export const updateUsersPermissionEP = '/users/permissions';
export const createSubscriptionPlanEP = '/plans/create';
export const updateSubscriptionPlanEP = '/plans/update';
export const ordersUpdateEp = '/orders/update';
export const exportOrderEP = '/orders/export';
export const exportCartEP = '/carts/export';
export const getInvitationsEP = '/my/invitations';
export const updateInvitationPermissionEP = '/invitations/permissions';
export const subscriptionSalvageEP = '/transactions/reports/salvage';
export const searchRiskEP = '/transactions/reports/risk';
export const reportsDisputeCodesEP = '/transactions/reports/dispute_codes';
export const getStoresEp = '/stores/search';
export const listUserInvitations = 'stores/invitations';
export const listUserPermissions = 'store/users/permissions';
export const removeUserPermissions = 'users/permissions/remove';
export const uniqueTransactionStatsEP = '/transactions/stats/unique';
export const deleteInvitesEP = 'users/invite/remove';
export const transactionCaptureEP = '/transactions/capture';
export const getStoresEventEP = '/events/search';
export const updateStoreFrontEventsEP = 'events/update';
export const getCreatePlanEP = '/plans/create';
export const getUpdatePlanEP = '/plans/update';
export const getMembershipOffersEP = `/store/storefront/memberships`;
export const createMembershipOffersEP = `/memberships/create`;
export const updateMembershipOffersEP = `/memberships/update`;
export const getProducts = `/products`;
export const batchProducts = `/products/batch`;
export const getMembershipProducts = 'store/storefront/memberships/products';
export const getPagesEP = '/page_templates/search';
export const createPagesEP = '/page_templates/create';
export const createShippingEP = '/shipping_methods/create';
export const createConversionTracking = '/conversion/settings';
export const updateConversionTracking = '/conversion/settings/update';
export const deleteConversionTracking = '/conversion/settings/remove';
export const updatePagesEP = '/page_templates/update';
export const getProcessrorAccounts = '/processor_accounts/search';
export const createProcessrorAccounts = '/processor_accounts/create';
export const updateProcessrorAccounts = '/processor_accounts/update';
export const deleteProcessorAccounts = '/processor_accounts/remove';
export const updateShippingEP = '/shipping_methods/update';
export const searchShippingEP = 'shipping_methods/search';
export const removeShippingEP = '/shipping_methods/remove';
export const subscriptionExportEP = '/subscriptions/export';
export const subscriptionEventsEP = '/subscription-events/search';
export const getCheckoutPagesEP = 'pages/search';
export const createCheckoutPagesEP = 'pages/create';
export const updateCheckoutPagesEP = 'pages/update';
export const deleteCheckoutPagesEP = 'pages/remove';
export const getRegisterAppEP = '/shopify/is_register';
export const createPreUpSell = '/pre_upsells/create';
export const searchPreUpSell = '/pre_upsells/search';
export const removePreUpSell = '/pre_upsells/remove';
export const updatePreUpSell = '/pre_upsells/update';
export const createPostUpSell = '/post_upsells/create';
export const searchPostUpSell = '/post_upsells/search';
export const removePostUpSell = '/post_upsells/remove';
export const updatePostUpSell = '/post_upsells/update';
export const createProcessrorAssigments = '/processor_assignments/set';
export const getProcessrorAccountsAssigments = '/processor_assignments/get';
export const createTracking = '/trackers/settings';
export const updateTracking = '/trackers/settings/update';
export const deleteTracking = '/trackers/settings/remove';
export const createCheckoutFlowEP = '/checkout_flow/set';
export const getCheckoutFlowEP = '/checkout_flow/get';
export const getLifeTimSubscriptionEP = '/stats/transactions/by_date';
export const getAggregatedTransactionEP = '/stats/aggregated/transaction';
export const getUniqueApprovalEP = '/stats/unique/approvals';
export const getRefundAmountEP = '/stats/refunded_amount';
export const getDisputeCodeEP = '/transactions/reports/dispute_codes';
export const blockIPEP = '/black-list/add';
export const unblockIPEP = '/black-list/remove';

export const getRetentionReportLifeTimeEP =
  '/subscriptions/reports/retention/cycle/lifetime';

export const getStoreByIDEP = (shopID: string) => {
  return `/store/details/${shopID}`;
};

export const getOrderByIdEP = (store_id: string, order_id: string) => {
  return `/store/${store_id}/orders/${order_id}`;
};

export const getCartDetailsByIdEP = (channelID: string, cartID: string) => {
  return `/channel/${channelID}/carts/${cartID}`;
};

export const getOrderHistoryByIdEP = (store_id: string, order_id: string) => {
  return `/store/${store_id}/orders/${order_id}/transaction_history`;
};
export const getSubscriptionUpdateEp = () => {
  return `/subscriptions/update`;
};
export const getTransactionHistoryByIdEP = (
  store_id: string,
  transaction_id: string,
) => {
  return `/store/${store_id}/transactions/${transaction_id}/history`;
};

export const getNotesEP = (
  store_id: string,
  resource_type: string,
  resource_id: string,
) => {
  return `/store/${store_id}/${resource_type}/${resource_id}/notes`;
};
export const getSubscriptionMangementEP = () => {
  return `/subscriptions/search`;
};

export const deleteNotesEP = (
  store_id: string,
  resource_type: string,
  resource_id: string,
  note_id: string,
) => {
  return `/store/${store_id}/${resource_type}/${resource_id}/notes/${note_id}`;
};
export const getTransactionEp = (params: ITransaction) => {
  return `/store/${params?.storeId}/transactions/${params?.transactionId}`;
};
export const getCustomerDetailsEp = (params: IcusomerDetailsPayload) => {
  return `/store/${params?.store_id}/customers/${params?.customer_id}`;
};
export const getCustomerSubscriptionDetailsEp = (
  params: IcusomerDetailsPayload,
) => {
  return `/store/${params?.store_id}/customers/${params?.customer_id}/subscription`;
};
export const getCustomerpaymentProfilesEp = (
  params: IcusomerDetailsPayload,
) => {
  return `/store/${params?.store_id}/customers/${params?.customer_id}/payment_profiles`;
};
export const getCustomerShippingAddressEp = (
  params: IcusomerDetailsPayload,
) => {
  return `/store/${params?.store_id}/customers/${params?.customer_id}/shipping_addresses`;
};

export const searchStoresEp = (store: ISearchStore) => {
  const findQuery = () => {
    const { name, page = 0, limit = 10 } = store;
    const queryParams = [];
    if (name) queryParams.push('name=' + name);
    queryParams.push('page=' + page);
    queryParams.push('limit=' + limit);
    return queryParams.join('&');
  };
  return `/stores/search?${findQuery()}`;
};

export const getSubscriptionsHistoryEP = (
  store_id: string,
  subscription_id: string,
) => {
  return `/store/${store_id}/subscriptions/${subscription_id}/history`;
};

export const getSubscriptionPlansEP = (store: string) => {
  return `/stores/${store}/plans`;
};

export const getSettingsStoresEp = (store: string) => {
  return `/stores/${store}`;
};
export const getStoreFrontsEP = (store: string) => {
  return `/stores/${store}/storefronts`;
};
export const getStoreFrontEP = (store: string, storefront: string) => {
  return `/stores/${store}/storefronts/${storefront}`;
};
export const getShippingFrontEP = (
  storeId: string,
  shippingMethodId: string,
) => {
  return `store/${storeId}/shipping_methods/${shippingMethodId}`;
};
export const getLegacyPaymentGatewaysEP = (store: string) => {
  return `/stores/${store}/payment-gateway`;
};
export const getPaymentGatewaysEP = (store: string) => {
  return `/stores/${store}/payment-gateway/v2`;
};

export const getUsersEP = (store: string) => {
  return `/stores/${store}/users`;
};

export const getUsersPermissions = (userId: string) => {
  return `/admin/${userId}/permissions`;
};

export const getStoreInviteEP = (store_id: string) => {
  return `/stores/${store_id}/invitations`;
};
export const getAcceptInviteEP = (store_id: string, invite_id: string) => {
  return `/stores/${store_id}/invitations/${invite_id}/accept`;
};
export const getDeclineInviteEP = (store_id: string, invite_id: string) => {
  return `/stores/${store_id}/invitations/${invite_id}/decline`;
};
export const getdeleteInviteEP = (store_id: string, invite_id: string) => {
  return `/stores/${store_id}/invitations/${invite_id}`;
};
export const getGrantSuperAdminEP = (user_id: string) => {
  return '/users/' + user_id + '/grant_super_admin';
};
export const getRevokeSuperAdmin = (user_id: string) => {
  return '/users/' + user_id + '/revoke_super_admin';
};
export const getStoreById = (storeId: string) => {
  return `/stores/${storeId}`;
};

export const getRetentionEP = () => {
  return `/subscriptions/reports/retention/cycle`;
};

export const getRetentionCancellationEp = () => {
  return `/subscriptions/reports/retention/cancellations`;
};

export const getMembershipPlanEP = (store_id: string) => {
  return `/stores/${store_id}/plans`;
};

export const getMembershipPlanByIDEP = (store_id: string, plan_id: string) => {
  return `/stores/${store_id}/plans/${plan_id}`;
};

export const getDeletePlanEP = (store_id: string, plan_id: string) => {
  return getMembershipPlanByIDEP(store_id, plan_id);
};

export const getMembershipOfferByIDEP = (channel_id: string, id: string) => {
  return `/storefront/${channel_id}/memberships/${id}`;
};

export const getDeleteMembershipOffersEP = (channel_id: string, id: string) => {
  return `/storefront/${channel_id}/memberships/${id}`;
};

export const getConversionTrackingEP = (channel_id: string) => {
  return `/channel/${channel_id}/conversion_settings`;
};
export const getTrackingEP = (channel_id: string) => {
  return `/channel/${channel_id}/tracking_settings`;
};

export const getPage = (store_id: string, page_id: string) => {
  return `/store/${store_id}/pages/${page_id}`;
};

export const getStorePermissionsEP = (userId: string) => {
  return `/admin/${userId}/permissions`;
};

export const publicEndpoints = [loginEP, loginVerifyEP, refreshTokenEP];

const errorMessages: {
  [_: number]: {
    status: number;
    message: string;
  };
} = {
  400: {
    status: 400,
    message:
      'The server could not understand the request due to invalid syntax.',
  },
  401: {
    status: 401,
    message:
      'You are not authorized to access this resource. Please authenticate yourself.',
  },
  403: {
    status: 403,
    message: 'You do not have permission to access this resource.',
  },
  404: {
    status: 404,
    message: 'The page you requested could not be found.',
  },
  500: {
    status: 500,
    message:
      'An unexpected error occurred on the server. Please try again later.',
  },
  502: {
    status: 502,
    message: 'The server received an invalid response from an upstream server.',
  },
  503: {
    status: 503,
    message:
      'The server is currently unable to handle the request due to temporary overloading or maintenance of the server. Please try again later',
  },
  504: {
    status: 504,
    message:
      'The server did not receive a timely response from an upstream server.',
  },
  408: {
    status: 408,
    message:
      'The server did not receive a complete request within the time that it was prepared to wait.',
  },
  425: {
    status: 425,
    message:
      'The server is unwilling to risk processing a request that might be replayed.',
  },
  429: {
    status: 429,
    message:
      'You have made too many requests in a given amount of time. Please try again later.',
  },
};

export const except = 'ERR_CANCELED';

export const commonError = (
  error: AxiosError,
  showErrorNotification: boolean = false,
): IErrorResponse<AxiosError> => {
  if (error.isAxiosError && error.response) {
    if (error?.code !== except && showErrorNotification) {
      const response = error?.response?.data as {
        Msg: string;
        StatusCode: number;
      };
      const message =
        response?.Msg ||
        errorMessages?.[error.response?.status]?.message ||
        'Internal Server Error';
      showNotification('failed', message);
    }
    return { status: error?.status as number, message: error.message, error };
  } else {
    if (error?.code !== except && showErrorNotification)
      showNotification('failed', error.message);
    return { status: error?.status as number, message: error.message, error };
  }
};
