import { FC } from 'react';
import { IUsersFilter } from 'interface/userInterface';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'shared-components';
import TextField from 'components/common/textField';
import 'components/admins/filter/FilterForm/style.scss';
import { defaultUsersValues } from 'components/admins';
import { Select } from 'shared-components';

interface IProps {
  sideFilter: IUsersFilter;
  filterSubmission: (_data: IUsersFilter) => void;
  setPage: (_page: number) => void;
}

const FilterForm: FC<IProps> = ({ sideFilter, filterSubmission, setPage }) => {
  const methods = useForm<IUsersFilter>({
    defaultValues: sideFilter,
  });
  const { handleSubmit, reset, control } = methods;

  const onSubmit = (data: IUsersFilter) => {
    filterSubmission(data);
    setPage(1);
  };
  return (
    <div className="user-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="Email"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                label="Email"
                placeholder="Enter Email"
                value={value}
                fixedSize="md"
                onChange={e => onChange(e.target.value)}
              />
            );
          }}
        />
        <Controller
          name="SuperAdmin"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                onChange={onChange}
                value={value}
                label="Super Admin"
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Show super admin', value: 'true' },
                  { label: 'Show users', value: 'false' },
                ]}
              />
            );
          }}
        />
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              reset(defaultUsersValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
