/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputHTMLAttributes } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as ColoumnFilter } from 'assets/icons/columnFilter-icon.svg';
import FilterForm from 'components/transactionLog/transactionFilter/filterForm';
import ColumnFilter from 'components/transactionLog/transactionFilter/columnFilter';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { HeadCell } from 'components/transactionLog/index';
import FilterAction from 'components/common/filterAction';
import { setMyColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import ExportFilter from './ExportFilter';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import {
  ITransactionLogData,
  ITransactionLogFormFilter,
} from 'interface/transactionLog';

interface ISideFilter extends InputHTMLAttributes<HTMLInputElement> {
  headCellList: HeadCell[];
  setHeadCellList: (headCell: HeadCell[]) => void;
  selected: Map<string, ITransactionLogData>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTransactionList: React.Dispatch<
    React.SetStateAction<ITransactionLogData[]>
  >;
  sideFormFilter: ITransactionLogFormFilter;
  searchTransactionList: () => void;
  filterSubmission: (_data: ITransactionLogFormFilter) => void;
  resetSelected: () => void;
  exportList: () => void;
}

const SideFilter: React.FC<ISideFilter> = ({
  headCellList,
  setHeadCellList,
  // selected,
  // setLoading,
  // setTransactionList,
  sideFormFilter,
  filterSubmission,
  // searchTransactionList,
  resetSelected,
  exportList,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { transactionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );
  const { transaction } = useAppSelector(state => state.permission);
  const filterAction = [
    { key: 'Filters', icon: FilterIcon, className: 'margin_filter' },
    { key: 'Column Filters', icon: ColoumnFilter },
    { key: 'Download', icon: DownloadIcon },
  ];

  const handleChange = (val: string) => {
    if (transactionColumnFilter.indexOf(val) === -1) {
      dispatch(
        setMyColoumnDetails({
          transactionColumnFilter: [...transactionColumnFilter, val],
        }),
      );
    } else {
      dispatch(
        setMyColoumnDetails({
          transactionColumnFilter: [
            ...transactionColumnFilter.filter(each => each !== val),
          ],
        }),
      );
    }
    setHeadCellList(
      headCellList.map((each: HeadCell) =>
        each.label === val ? { ...each, hide: !each.hide } : each,
      ),
    );
  };

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'transactions', currentFilter: filter }),
    );
  };

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFormFilter={sideFormFilter}
            filterSubmission={filterSubmission}
            resetSelected={resetSelected}
          />
        );
      case 'Column Filters':
        return (
          <ColumnFilter
            filterList={headCellList}
            setSelectedColumns={handleChange}
          />
        );
      case 'Download':
        return <ExportFilter exportList={exportList} />;
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={transaction}
        setSelectedFilter={setSelectedFilter}
      />

      {!!currentFilter && filterSource === 'transactions' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
