import { useRef } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import 'components/storeFrontEvents/style.scss';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import Pagination from 'components/common/pagination';
import {
  IBlackListIP,
  IEventsFilter,
  IStoreFrontEvent,
  IStoreFrontEventResponse,
  IStoreFrontEventsFilter,
} from 'interface/storeInterface';
import globalStoreFrontService from 'services/storeFrontService';
import UpdateStoreFrontEvent from 'components/storeFrontEvents/updateStoreFrontEvent';
import SideFilter from 'components/storeFrontEvents/Filter/sideFilter';
import { useSearchParams } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import dayjs from 'dayjs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formattedDate } from 'utils/helper';
import NestedTableComponent from 'components/storeFrontEvents/storeFrontNestedTable';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import { ReactComponent as Locked } from 'assets/icons/locked.svg';
import { ReactComponent as UnLocked } from 'assets/icons/unlocked.svg';
import { Checkbox, showNotification } from 'shared-components';
import { useForm } from 'react-hook-form';
import IpPopup from './popups/IPPopup';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IStoreFrontEventResponse) => ReactNode;
  hide?: boolean;
  key: string;
  showInSearch?: boolean;
  showSortIcon?: boolean;
}

export const defaultStoreFrontEventValues: IStoreFrontEventsFilter = {
  eventName: '',
  time: '',
  resolved: 'no',
  storeFilter: {
    StoreIDs: [],
  },
  tags: [],
  ipAddress: '',
  StartTime: null,
  EndTime: null,
};

export default function StoreFrontEvents() {
  const [loading, setLoading] = useState<boolean>(false);
  const [blackListPopup, setBlackListPopup] = useState<boolean>(false);
  const [getIsBlacklisted, setGetIsBlacklisted] = useState<boolean>(false);
  const [totalPage, settotalPage] = useState<number>(0);
  const rowsPerPage = 25;
  const [storeFrontEvents, setStoreEvents] = useState<
    IStoreFrontEventResponse[]
  >([]);
  const [selectedEvent, setSelectedEvent] = useState<IStoreFrontEventResponse>({
    ChannelID: '',
    ChannelName: '',
    CreatedAt: '',
    Data: {},
    EventName: '',
    ID: '',
    Level: '',
    Name: '',
    Resolved: false,
    Store: {
      ID: '',
      Name: '',
    },
    Note: '',
    Tags: [],
    IPAddress: '',
    Version: 0,
    IsBlacklisted: false,
  });
  const disPatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get('pageCount') || 1),
  );
  const [width, setWidth] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLInputElement>(null);
  const [openRows, setOpenRows] = useState<boolean[]>();
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const { timeZone } = useAppSelector(state => state.pathConfig);

  const [sideFormFilter, setSideFormFilter] = useState<IStoreFrontEventsFilter>(
    {
      time: searchParams.get('time')
        ? JSON.parse(searchParams.get('time') || '')
        : defaultStoreFrontEventValues.time,
      eventName: searchParams?.get('eventName')
        ? JSON.parse(searchParams.get('eventName') || '')
        : defaultStoreFrontEventValues.eventName,
      resolved: searchParams.get('resolved')
        ? JSON.parse(searchParams.get('resolved') || '')
        : defaultStoreFrontEventValues.resolved,
      storeFilter: {
        StoreIDs: searchParams.get('store_ids')
          ? JSON.parse(searchParams.get('store_ids') || '')
          : defaultStoreFrontEventValues.storeFilter.StoreIDs,
      },
      ipAddress: searchParams.get('ipAddress')
        ? JSON.parse(searchParams.get('ipAddress') || '')
        : defaultStoreFrontEventValues.ipAddress,
      tags: searchParams.get('tags')
        ? JSON.parse(searchParams.get('tags') || '')
        : defaultStoreFrontEventValues.tags,
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : defaultStoreFrontEventValues.StartTime,
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : defaultStoreFrontEventValues.EndTime,
    },
  );
  const [filter, setFilter] = useState<IEventsFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
  });
  const [selected, setSelected] = useState<
    Map<string, IStoreFrontEventResponse>
  >(new Map());
  const IPform = useForm<IBlackListIP>({
    defaultValues: {
      Description: 'Black List IP',
      IpAddress: '',
    },
  });

  const { setValue } = IPform;

  const headCellList: readonly HeadCell[] = [
    {
      id: 'CreatedAt',
      label: 'Time',
      key: 'date',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
      showInSearch: true,
    },
    {
      id: 'Store.Name',
      label: 'Store',
      key: 'store',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'ChannelName',
      label: 'ChannelName',
      key: 'name',
      hide: false,
      cellRender: row => {
        return row.ChannelName;
      },
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'EventName',
      label: 'Event Name',
      key: 'event_name',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row.EventName;
      },
    },
    {
      id: 'Level',
      label: 'Level',
      key: 'level',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
      cellRender: row => {
        return row.Level;
      },
    },
    {
      id: 'Resolved',
      label: 'Resolved',
      key: 'resolved',
      hide: false,
      cellRender: row => {
        return row.Resolved == true ? 'Yes' : 'No';
      },
      showSortIcon: true,
    },
    {
      id: 'Tags',
      label: 'Tags',
      key: 'tags',
      hide: false,
      cellRender: row => {
        return row.Tags;
      },
      showSortIcon: false,
      showInSearch: false,
    },
    {
      id: 'IPAddress',
      label: 'IP Address',
      key: 'ipAddress',
      hide: false,
      cellRender: row => {
        return row.IPAddress;
      },
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Notes',
      label: 'Notes',
      key: 'notes',
      hide: false,
      cellRender: row => {
        return row.Note;
      },
      showSortIcon: false,
      showInSearch: false,
    },
    {
      id: 'Actions',
      label: 'Actions',
      key: 'actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex gap-4">
            <Tooltip title="Click to Edit" placement="top">
              <CreateIcon
                onClick={() => {
                  setIsEdit(val => !val);
                  setSelectedEvent(row);
                }}
              />
            </Tooltip>
            {row?.IsBlacklisted ? (
              <Tooltip title="Blocked IP, Click to Open" placement="top">
                <Locked
                  className="close-icon w-4"
                  onClick={() => {
                    setBlackListPopup(true);
                    setValue('IpAddress', row?.IPAddress || '');
                    setGetIsBlacklisted(row?.IsBlacklisted);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Open IP, Click to Block" placement="top">
                <UnLocked
                  className="close-icon w-5"
                  onClick={() => {
                    setBlackListPopup(true);
                    setValue('IpAddress', row?.IPAddress || '');
                    setGetIsBlacklisted(row?.IsBlacklisted);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
      showSortIcon: false,
      showInSearch: false,
    },
  ];

  const searchStoreFrontEvents = async () => {
    setOpenRows([]);
    const payload: IStoreFrontEvent = {
      ...filter,
      Page: page - 1,
    };
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length > 0) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (sideFormFilter.tags.length > 0) {
      payload.Tags = sideFormFilter.tags;
    }

    if (sideFormFilter.eventName) {
      payload.EventName = sideFormFilter.eventName;
    }
    if (sideFormFilter.ipAddress) {
      payload.IPAddress = sideFormFilter.ipAddress;
    }
    if (sideFormFilter.resolved) {
      if (sideFormFilter.resolved === 'all') {
        payload.Resolved = null;
      } else payload.Resolved = sideFormFilter.resolved === 'yes';
    }
    if (sideFormFilter?.StartTime) {
      payload.StartTime = dayjs(sideFormFilter?.StartTime)
        .tz()
        .startOf('day')
        .format();
    }

    if (sideFormFilter?.EndTime) {
      payload.EndTime = dayjs(sideFormFilter?.EndTime)
        .tz()
        .add(1, 'day')
        .startOf('day')
        .format();
    }

    setSearchParams(
      {
        time: JSON.stringify(sideFormFilter.time),
        eventName: JSON.stringify(sideFormFilter.eventName),
        resolved: JSON.stringify(sideFormFilter.resolved),
        tags: JSON.stringify(sideFormFilter.tags),
        store_ids: JSON.stringify(sideFormFilter.storeFilter.StoreIDs),
        ipAddress: JSON.stringify(sideFormFilter.ipAddress),
        descending: JSON.stringify(payload.Descending),
        orderBy: JSON.stringify(payload.OrderBy),
        pageCount: JSON.stringify(page),
        StartTime: sideFormFilter.StartTime
          ? sideFormFilter.StartTime.tz().format()
          : '',
        EndTime: sideFormFilter.EndTime
          ? sideFormFilter.EndTime.tz().format()
          : '',
        Limit: String(limit),
      },
      { replace: true },
    );
    setLoading(true);
    const res = await globalStoreFrontService.getStoreFrontEvents(payload);
    setLoading(false);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setStoreEvents(res?.data?.Result);
        settotalPage(res?.data?.TotalCount);
      } else {
        setStoreEvents([]);
        settotalPage(0);
      }
    } else {
      setStoreEvents([]);
      settotalPage(0);
    }
  };

  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const handleClose = () => {
    setBlackListPopup(false);
    setValue('Description', 'Black List IP');
  };

  const handleConform = async (payload: IBlackListIP) => {
    if (getIsBlacklisted) {
      delete payload.Description;
      const res = await globalStoreFrontService.unBlockIP(payload);
      if (res?.status === 200) {
        showNotification('success', 'IP Unblocked Successfully');
        searchStoreFrontEvents();
        handleClose();
      }
    } else {
      const res = await globalStoreFrontService.blockIP(payload);
      if (res?.status === 200) {
        showNotification('success', 'IP Blocked Successfully');
        searchStoreFrontEvents();
        handleClose();
      }
    }
  };

  const handleRowClick = (
    event: IStoreFrontEventResponse,
    checked: boolean,
  ) => {
    if (checked) {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(event.ID);
        return newSelected;
      });
    } else {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.set(event.ID, event);
        return newSelected;
      });
    }
  };

  const selectAll = (checked: boolean) => {
    if (checked) {
      const allRows = new Map(
        storeFrontEvents.map(row => {
          return [`${row.ID}`, row];
        }),
      );
      setSelected(allRows);
    } else {
      setSelected(new Map());
    }
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  useEffect(() => {
    searchStoreFrontEvents();
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
      disPatch(setCurrentFilter(''));
    };
  }, [sideFormFilter, page, limit, storeIds, timeZone, filter]);

  return (
    <>
      <div className="store_front_events">
        <div className="shopify_wrapper">
          <div className="store_management">
            <TableContainer className="Common_Table">
              <Table
                className="risk_management_table"
                aria-labelledby="tableTitle"
                stickyHeader>
                <TableHead className="table_header">
                  <TableRow>
                    <TableCell className="resolved_header" />
                    <TableCell className="table_header_cell" />
                    <TableCell className="table_header_cell">
                      <div className="flex-row">
                        <Checkbox
                          checked={
                            storeFrontEvents.length > 0 &&
                            selected.size === storeFrontEvents.length
                          }
                          onChange={(_, checked) => selectAll(checked)}
                          inputProps={{
                            'aria-labelledby': 'enhanced-table-checkbox-all',
                          }}
                        />
                      </div>
                    </TableCell>
                    {headCellList.map(headCell => {
                      if (headCell.hide) {
                        return null;
                      }
                      return (
                        // <TableCell
                        //   className="table_header_cell header_text"
                        //   key={headCell.label}>
                        //   {headCell.label}
                        // </TableCell>
                        <TableCell
                          className="table_header_cell"
                          key={headCell.key}>
                          {headCell?.showSortIcon ? (
                            <TableSortLabel
                              className="header_text"
                              active={filter.OrderBy === headCell.id}
                              direction={
                                filter?.OrderBy === headCell.id
                                  ? filter.Descending
                                    ? 'desc'
                                    : 'asc'
                                  : 'asc'
                              }
                              onClick={() => sortHandler(headCell.id)}>
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {storeFrontEvents?.length > 0 ? (
                    storeFrontEvents.map((row, index) => {
                      const isItemSelected = !!selected.get(`${row.ID}`);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.ID}
                            aria-checked={isItemSelected}
                            selected={isItemSelected}>
                            <TableCell
                              className={`resolved_row ${row?.Resolved ? 'resolved' : 'not_resolved'}`}
                            />
                            <TableCell className="table_cell">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                className="expand-row"
                                onClick={() => handleRowToggle(index)}>
                                {(openRows ? openRows[index] : false) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              padding="checkbox"
                              className="table_cell">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                                onChange={() =>
                                  handleRowClick(row, isItemSelected)
                                }
                              />
                            </TableCell>
                            {headCellList.map(headCell => {
                              if (headCell.hide) {
                                return null;
                              }
                              return (
                                <TableCell
                                  className="table_cell"
                                  key={headCell.label}
                                  component="th"
                                  id={headCell.id}
                                  scope="row">
                                  <>
                                    {headCell?.cellRender
                                      ? headCell.cellRender(row)
                                      : get(row, headCell.id)}
                                  </>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {(openRows ? openRows[index] : false) && (
                            <TableRow className="row_collapsed">
                              <TableCell
                                className="table_border_none nested-table-cell"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={15}>
                                <Collapse
                                  in={openRows ? openRows[index] : false}
                                  timeout="auto"
                                  unmountOnExit>
                                  <div
                                    style={{ width: width ? width : 'auto' }}>
                                    <NestedTableComponent
                                      row={row.Data ? row.Data : null}
                                    />
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <div className="no-data-row">No data found</div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              totalData={totalPage}
              limit={limit}
              onRowsPerChange={val => {
                setLimit(val);
              }}
              perPage={limit}
              currentPage={page}
              onHandleChange={page => {
                setPage(page);
              }}
            />
          </div>
          <Loader loading={loading} />
        </div>
        {isEdit && (
          <UpdateStoreFrontEvent
            handleClose={() => setIsEdit(false)}
            payload={selectedEvent}
            onSuccess={searchStoreFrontEvents}
          />
        )}
        <SideFilter
          sideFilter={sideFormFilter}
          setPage={setPage}
          filterSubmission={data => {
            setSideFormFilter(data);
          }}
          selected={selected}
          searchAPI={searchStoreFrontEvents}
          setLoading={setLoading}
          resetSelected={() => setSelected(new Map())}
          filterList={['Tags']}
        />
        {blackListPopup && (
          <IpPopup
            open={blackListPopup}
            handleClose={handleClose}
            loading={loading}
            getIsBlacklisted={getIsBlacklisted}
            IPform={IPform}
            handleConform={handleConform}
          />
        )}
      </div>
    </>
  );
}
