import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
} from '@mui/material';
import './style.scss';
import Pagination from 'components/common/pagination';
import { Loader } from 'components/common/loader';
import { useAppSelector } from 'hooks/reduxHooks';
import SideFilter from './filter';
import globalUserService from 'services/userService';
import { IAllUsers } from 'interface/settingsInterface';
import { IInvitation, IUsersFilter } from 'interface/userInterface';
import { get } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import userService from 'services/userService';
import SearchFilter from 'components/common/searchFilter';
import { iterateHeadCellKeys } from 'utils/helper';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as RemoveAdmin } from 'assets/icons/remove-admin.svg';
import { ReactComponent as AddUser } from 'assets/icons/add-user.svg';
import { PopUpModal, showNotification } from 'shared-components';
import AddUsers from './AddUser';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NestedTableComponent from './nestedTableComponent';
export interface HeadCellAdmin {
  id: string;
  label: string;
  cellRender?: (_row: IInvitation) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFieldOptions?: { label: string; value: string }[];
  searchFiedtType?: 'input' | 'select';
  inputType?: string;
}
export interface SearchProps {
  [key: string]: string;
}

export const defaultUsersValues: IUsersFilter = {
  Email: '',
  FirstName: '',
  LastName: '',
  SuperAdmin: 'all',
};

const rowsPerPage = 25;

const Admins = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearchValue] = useState<SearchProps>({});
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const { storeIds } = useAppSelector(state => state?.storeIds);
  const [totalPage, settotalPage] = useState<number>(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [showAddUsers, setShowAddUsers] = useState<boolean>(false);
  const [user, setUser] = useState<IInvitation | null>(null);
  const [usersList, setUsersList] = useState<IInvitation[]>([]);
  const [openRows, setOpenRows] = useState<boolean[]>();
  const [width, setWidth] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLInputElement>(null);
  const [sideFormFilter, setSideFormFilter] = useState<IUsersFilter>({
    Email: searchParams.get('Email')
      ? JSON.parse(searchParams.get('Email') || '')
      : defaultUsersValues.Email,
    FirstName: searchParams?.get('FirstName')
      ? JSON.parse(searchParams.get('FirstName') || '')
      : defaultUsersValues.FirstName,
    LastName: searchParams.get('LastName')
      ? JSON.parse(searchParams.get('LastName') || '')
      : defaultUsersValues.LastName,
    SuperAdmin: searchParams.get('SuperAdmin')
      ? JSON.parse(searchParams.get('SuperAdmin') || '')
      : defaultUsersValues.SuperAdmin,
  });
  const [page, setPage] = useState<number>(
    Number(searchParams.get('PageCount') || 1),
  );
  const generateUsersPayload = (isSetSearchParams = true) => {
    const { Email, FirstName, LastName, SuperAdmin } = sideFormFilter;
    const payload: IAllUsers = {
      Descending: true,
      Limit: limit,
      OrderBy: 'CreatedAt',
      Page: page - 1,
      SuperAdmin: SuperAdmin === 'all' ? undefined : JSON.parse(SuperAdmin),
    };
    if (Email) payload.Email = Email;
    if (FirstName) payload.FirstName = FirstName;
    if (LastName) payload.LastName = LastName;
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
      payload.Page = 0;
      setPage(1);
    }
    if (isSetSearchParams) {
      setSearchParams(
        {
          PageCount: String(page),
          ...(Email && { Email: JSON.stringify(Email) }),
          ...(FirstName && { FirstName: JSON.stringify(FirstName) }),
          ...(LastName && { LastName: JSON.stringify(LastName) }),
          ...(SuperAdmin && { SuperAdmin: JSON.stringify(SuperAdmin) }),
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };

  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const headCells: readonly HeadCellAdmin[] = [
    {
      id: 'Email',
      label: 'Email',
      showInSearch: true,
    },
    {
      id: 'FirstName',
      label: 'First Name',
      showInSearch: true,
      hide: false,
    },
    {
      id: 'LastName',
      label: 'Last Name',
      showInSearch: true,
      hide: false,
    },
    {
      id: 'SuperAdmin',
      label: 'Super Admin',
      cellRender: row => {
        return (
          <div className={row.SuperAdmin ? 'success_text' : 'danger_text'}>
            {row.SuperAdmin ? 'Yes' : 'No'}
          </div>
        );
      },
      showInSearch: true,
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'actions',
      label: 'Actions',
      cellRender: (row: IInvitation) => {
        return (
          <Tooltip
            title={
              row.SuperAdmin ? 'Remove Super Admin' : 'Promote to Super admin'
            }
            onClick={() => {
              setOpenPopup(true);
              setUser(row);
            }}
            arrow>
            {row.SuperAdmin ? <RemoveAdmin /> : <AddUser />}
          </Tooltip>
        );
      },
    },
  ];

  const filteredField = iterateHeadCellKeys([...headCells]);

  const getAdmins = async () => {
    setLoading(true);
    const res = await userService.getAllUsers(generateUsersPayload());

    setLoading(false);
    if (res?.status === 200) {
      if (res?.data && res?.data?.Result?.length) {
        settotalPage(res?.data?.TotalCount);
        setIsNextBtnDisabled(res?.data?.Result?.length < limit);
        setUsersList(res?.data?.Result);
      } else {
        setUsersList([]);
        settotalPage(0);
      }
    } else {
      setUsersList([]);
      settotalPage(0);
    }
  };

  const handleSuperAdminChange = async (user: IInvitation | null) => {
    setLoading(true);
    try {
      if (!user || !user?.ID) return;
      const payload = {
        UserID: user?.ID,
        SuperAdmin: user.SuperAdmin ? false : true,
        Version: user.Version,
      };
      const response = await globalUserService.updateAdminUser(payload);
      if (response?.status === 200) {
        const message = user.SuperAdmin
          ? `${user?.Email} Removed as Super Admin`
          : `${user?.Email} Added as Super Admin`;
        showNotification('success', message);
        getAdmins();
      }
    } catch (error) {
      showNotification('error', 'Failed to add Super Admin');
    } finally {
      setOpenPopup(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    getAdmins();
  }, [page, storeIds, limit, search, sideFormFilter]);

  return (
    <div className="admin_top_container">
      <div className="admin_left_container">
        <div className="admin_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={setSearchValue}
            searchValue={search}
          />
        </div>
        <div className="relative h-full overflow-auto">
          <TableContainer className="Common_Table !h-fit max-h-full" ref={ref}>
            <Table stickyHeader className="h-full">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {headCells.map((headCell, index) => {
                    if (headCell?.hide) {
                      return null;
                    }
                    return (
                      <TableCell key={index}>
                        <span
                          className={
                            headCell?.label == 'Actions' ? 'text-end block' : ''
                          }>
                          {headCell?.label}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList && usersList.length > 0 ? (
                  usersList?.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell className="table_cell">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              className="expand-row"
                              onClick={() => handleRowToggle(index)}>
                              {(openRows ? openRows[index] : false) ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          {headCells.map((headCell, index) => {
                            if (headCell?.hide) {
                              return null;
                            }
                            return (
                              <TableCell key={index}>
                                <span
                                  className={
                                    headCell?.label == 'Actions'
                                      ? 'text-end flex items-center justify-end'
                                      : ''
                                  }>
                                  {headCell?.cellRender
                                    ? headCell?.cellRender(row)
                                    : get(row, headCell.id)}
                                </span>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {(openRows ? openRows[index] : false) && (
                          <TableRow className="row_collapsed">
                            <TableCell
                              className="table_border_none nested-table-cell"
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={9}>
                              <Collapse
                                in={openRows ? openRows[index] : false}
                                timeout="auto"
                                unmountOnExit>
                                <div style={{ width: width ? width : 'auto' }}>
                                  <NestedTableComponent row={row} />
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="no-data-row !translate-y-[-50%] !translate-x-[-50%]">
                    No data found
                  </div>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={10}>
                    <button
                      className="sticky-button flex items-center"
                      onClick={() => setShowAddUsers(true)}>
                      <AddIcon /> Add User
                    </button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Pagination
          perPage={page}
          totalData={totalPage}
          onRowsPerChange={val => {
            setLimit(val);
          }}
          limit={limit}
          currentPage={page}
          isNextDisabled={isNextBtnDisabled}
          onHandleChange={page => {
            setPage(page);
          }}
        />
        {openPopup && (
          <PopUpModal
            open={true}
            handleClose={() => {
              setOpenPopup(false);
            }}
            buttons={[
              {
                buttonType: 'negative',
                buttonLabel: 'Cancel',
                buttonFunction: () => {
                  setOpenPopup(false);
                },
              },
              {
                buttonType: 'positive',
                buttonLabel: 'Confirm',
                type: 'submit',
                disabled: loading,
                buttonFunction: () => {
                  handleSuperAdminChange(user);
                },
                loading,
              },
            ]}
            heading={'Revoke Admin'}>
            <div className="popup-section !w-full">
              <p className="popup-question">{`Are you sure want to ${user?.SuperAdmin ? 'revoke' : 'grant'} permission for this "${user?.Email}"?`}</p>
            </div>
          </PopUpModal>
        )}
        {showAddUsers && (
          <AddUsers setShowAddUsers={setShowAddUsers} onSuccess={getAdmins} />
        )}
      </div>
      <SideFilter
        sideFilter={sideFormFilter}
        setPage={setPage}
        filterSubmission={data => {
          setSideFormFilter(data);
        }}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default Admins;
