import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { v4 as uuidv4 } from 'uuid';
import { Guarantee } from './guarantee';
import Button from '../../../components/common/button';
import { IAsset } from '../../interface/apiServiceInterface';
import {
  defaultImage,
  ImageSelector,
} from '../../../components/common/image-selector/ImageSelector';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../components/common/tooltip';
import { QuillTextEditor } from '../../puck/reusable-props/quill-editor';
export type CustomerGuarnteesProps = {
  guarantees: GuaranteeData[];
  border: boolean;
  title: string;
} & MarginProps;

export interface GuaranteeData {
  id: string;
  image: IAsset;
  name: string;
  description: string;
}

export const CustomerGuarantees = (
  baseService: AxiosInstance,
): ComponentConfig<CustomerGuarnteesProps> => {
  return {
    label: (
      <CustomTooltip title="CustomerGuarantees">
        <span>Customer Guarantees</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      title: {
        ...QuillTextEditor('Title').content,
      },
      border: {
        type: 'radio',
        label: 'Divider Line',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
      guarantees: {
        type: 'custom',
        label: 'Guarantee',
        render: ({ value, onChange }) => {
          value = value || [];

          const handleAddGuarantee = () => {
            const newGuarantee: GuaranteeData = {
              id: uuidv4(),
              image: defaultImage,
              name: '',
              description: '',
            };
            onChange([newGuarantee, ...value]);
          };

          const handleEditGuarantee = (
            index: number,
            key: keyof GuaranteeData,
            newValue: IAsset | string,
          ) => {
            onChange(
              value.map((guarantee: GuaranteeData, i: number) =>
                i === index ? { ...guarantee, [key]: newValue } : guarantee,
              ),
            );
          };

          const handleRemoveGuarantee = (index: number) => {
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };
          return (
            <div>
              <Button
                label="+ Add new Guarantee"
                className="mt-4 w-full"
                variant="phoenix"
                onClick={handleAddGuarantee}
              />
              {value.map((guarantee: GuaranteeData, index: number) => (
                <div key={guarantee.id} className="mt-4">
                  <ImageSelector
                    handleSubmit={selectedImage => {
                      handleEditGuarantee(index, 'image', selectedImage);
                    }}
                    selectedImage={guarantee.image}
                    baseService={baseService}
                  />
                  <input
                    className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                    placeholder="Name"
                    value={guarantee?.name}
                    onChange={e =>
                      handleEditGuarantee(index, 'name', e.target.value)
                    }
                  />
                  <textarea
                    className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                    placeholder="Description"
                    value={guarantee.description}
                    onChange={e =>
                      handleEditGuarantee(index, 'description', e.target.value)
                    }
                  />
                  <Button
                    label="- Remove Guarantee"
                    className="mt-4 w-full"
                    variant="phoenix_secondary"
                    onClick={() => handleRemoveGuarantee(index)}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      ...marginFields,
    },
    defaultProps: {
      title: 'Why Us',
      border: true,
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      guarantees: [],
    },
    render: ({ margin, guarantees, border, title }) => {
      return (
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <div className="pb-5 border-b border-gray-200">
            <div className="flex items-center">
              {border && (
                <div className="flex-grow border-b border-black mx-2"></div>
              )}
              <div
                className={`quill-text ${!border ? 'w-full' : ''}`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {border && (
                <div className="flex-grow border-b border-black mx-2"></div>
              )}
            </div>
            {guarantees && guarantees.length === 0 && (
              <div className="mt-10 text-center text-lg text-gray-600">
                No Guarantees yet
              </div>
            )}

            {guarantees?.map(data => {
              return (
                <Guarantee
                  key={data.id}
                  id={data.id}
                  image={data?.image}
                  name={data.name}
                  description={data.description}
                />
              );
            })}
          </div>
        </div>
      );
    },
  };
};
