import React, { FC, useState } from 'react';
import 'components/SubscriptionManagement/ActionFilter/style.scss';
import {
  ISubscriptionManagementUpdateBody,
  ISubscrptionManagement,
} from 'interface/subscriptionManagementInterface';
import { showNotification } from 'helper/common/commonFunctions';
import {
  SubscriptionStatus,
  actionEvents,
} from 'components/SubscriptionManagement/constant';
import CancelSubscriptionAction from 'components/Actions/cancelSubscription';
import ChangeSubscriptionValue from 'components/Actions/changeSubscriptionValue';
import ChangeBillingDate from 'components/Actions/changeBillingDate';
import ReactivateSubscription from 'components/Actions/reactivateSubscription';
import MoveSubscription from 'components/Actions/moveSubscription';
import ChangeSubscription from 'components/Actions/changeSubscription';

interface ISubscriptionAndUpdate {
  payload: ISubscriptionManagementUpdateBody;
  value: ISubscrptionManagement;
}
interface IProps {
  filterList: string[];
  selected: Map<string, ISubscrptionManagement>;
  setSubscriptionManagementData: React.Dispatch<
    React.SetStateAction<ISubscrptionManagement[]>
  >;
  searchOrderList: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelected: () => void;
}

export const ActionFilter: FC<IProps> = ({
  filterList,
  selected,
  resetSelected,
  searchOrderList,
  setSubscriptionManagementData,
}) => {
  const [isPopupOpen, setPopupOpen] = useState<{
    cancel_subscription: boolean;
    reactivate_subscription: boolean;
    move_subscription: boolean;
    change_next_billing_date: boolean;
    change_subscription_value: boolean;
    change_subscription: boolean;
  }>({
    cancel_subscription: false,
    reactivate_subscription: false,
    move_subscription: false,
    change_next_billing_date: false,
    change_subscription_value: false,
    change_subscription: false,
  });
  const changeVersion = (
    payload: ISubscriptionManagementUpdateBody,
    value: ISubscrptionManagement,
  ) => {
    setSubscriptionManagementData(pre =>
      pre.map(val => {
        if (val.ID === payload.ID) {
          return {
            ...val,
            version: payload?.Version + 1,
          };
        }
        return val;
      }),
    );
    value.Version += 1;
  };
  const getUpdateSubscriptionPayload = (
    selected: Map<string, ISubscrptionManagement>,
  ) => {
    const datas: ISubscriptionAndUpdate[] = [...selected.values()]?.map(
      value => {
        const payload: ISubscriptionManagementUpdateBody = {
          StoreID: value?.Store?.ID,
          ID: value.ID,
          Version: value?.Version,
          Status: value.Status,
          NextBillingDate: value.NextBillingDate,
          Amount: value.Amount,
          Frequency: value.Frequency,
          Interval: value.Interval,
          AuthPeriod: value.AuthPeriod,
          AuthPeriodUnit: value.AuthPeriodUnit,
        };
        return { payload, value };
      },
    );
    return datas[0];
  };

  const handleClose = (value: string) => {
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };

  const handleOpen = (value: string) => {
    value = value.toLowerCase().replaceAll(' ', '_');
    if (selected?.size === 1) {
      setPopupOpen(pre => ({ ...pre, [value]: true }));
    } else if (selected?.size > 1) {
      showNotification('failed', 'This actions is not compatible');
    } else {
      showNotification('failed', 'Please select at least one Subscription');
    }
  };

  return (
    <div className="subscription_action_filter_container">
      {isPopupOpen.cancel_subscription && (
        <CancelSubscriptionAction
          payload={getUpdateSubscriptionPayload(selected)?.payload}
          handleClose={() => {
            handleClose(actionEvents?.cancel_subscription);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              searchOrderList();
              const { payload, value } = getUpdateSubscriptionPayload(selected);
              value.Status = SubscriptionStatus.subscriptionStatusCanceled;
              changeVersion(payload, value);
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen.change_subscription_value && (
        <ChangeSubscriptionValue
          payload={getUpdateSubscriptionPayload(selected)?.payload}
          handleClose={() => {
            handleClose(actionEvents?.change_subscription_value);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              const { payload, value } = getUpdateSubscriptionPayload(selected);
              changeVersion(payload, value);
              searchOrderList();
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen.change_next_billing_date && (
        <ChangeBillingDate
          payload={getUpdateSubscriptionPayload(selected)?.payload}
          handleClose={() => {
            handleClose(actionEvents?.change_next_billing_date);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              const { payload, value } = getUpdateSubscriptionPayload(selected);
              changeVersion(payload, value);
              searchOrderList();
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen.move_subscription && (
        <MoveSubscription
          payload={getUpdateSubscriptionPayload(selected)?.payload}
          handleClose={() => {
            handleClose(actionEvents?.move_subscription);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              const { payload, value } = getUpdateSubscriptionPayload(selected);
              changeVersion(payload, value);
              searchOrderList();
              resetSelected();
            }
          }}
        />
      )}
      {isPopupOpen.reactivate_subscription && (
        <ReactivateSubscription
          payload={getUpdateSubscriptionPayload(selected)?.payload}
          handleClose={() => {
            handleClose(actionEvents?.reactivate_subscription);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              const { payload, value } = getUpdateSubscriptionPayload(selected);
              changeVersion(payload, value);
              searchOrderList();
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen?.change_subscription && (
        <ChangeSubscription
          handleClose={() => {
            setPopupOpen(pre => ({ ...pre, change_subscription: false }));
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_subscription');
              searchOrderList();
            }
            resetSelected();
          }}
          payload={getUpdateSubscriptionPayload(selected)?.payload}
        />
      )}
      {filterList?.map((cols: string, index: number) => (
        <div
          className="action_filter_columns"
          key={index}
          onClick={() => {
            handleOpen(cols);
          }}>
          {cols}
        </div>
      ))}
    </div>
  );
};
