import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  ICaptureTransaction,
  ICommonUpdateTransaction,
  IDisputeTransactionBody,
  IRefundTransaction,
  ISearchTransactionBody,
  ITransactioHistoryDetails,
  ITransactionsDetails,
  IVoidTransaction,
  IupdateTransactionBody,
} from 'interface/transactionInterface';
import {
  commonError,
  getTransactionHistoryByIdEP,
  subscriptionSalvageEP,
  transactionCaptureEP,
  transactionChargeChargebackEP,
  transactionChargeDisputeEP,
  transactionDisputeEP,
  transactionExportEP,
  transactionLog,
  transactionRefundEP,
  transactionSearchEP,
  transactionVoidEP,
  transactionsEP,
} from 'services/constants';
import {
  IErrorResponse,
  IResponse,
  ISuccessResponse,
} from 'interface/apiInterface';
import {
  mockTransactionHistoryData,
  transactionsDataMock,
} from 'utils/mockData';
import {
  ISearchSalvageBody,
  ISubscriptionSalvageResult,
} from 'interface/subscriptionSalvage';
import { ISearchTransactionLogBody } from 'interface/transactionLog';

class TransactionService {
  updateTransaction = async (
    body: IupdateTransactionBody,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ITransactionsDetails>(
        transactionsEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  searchTransaction = async (
    body: ISearchTransactionBody,
  ): Promise<
    ISuccessResponse<ITransactionsDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ITransactionsDetails>(
        transactionSearchEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  voidTransaction = async (
    body: IVoidTransaction,
  ): Promise<
    ISuccessResponse<{ TransactionID: string }> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionVoidEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  refundTransaction = async (
    body: IRefundTransaction,
  ): Promise<
    ISuccessResponse<{ TransactionID: string }> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionRefundEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getTransactionHistory = async (
    store_id: string,
    transaction_id: string,
  ): Promise<
    ISuccessResponse<ITransactioHistoryDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<ITransactioHistoryDetails>(
        getTransactionHistoryByIdEP(store_id, transaction_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  disputeTransaction = async (
    body: IupdateTransactionBody,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionDisputeEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  captureTransaction = async (
    body: ICaptureTransaction,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionCaptureEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getSalvageSubscription = async (
    body: ISearchSalvageBody,
  ): Promise<
    ISuccessResponse<ISubscriptionSalvageResult> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ISubscriptionSalvageResult>(
        subscriptionSalvageEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  transactionLog = async (
    body: ISearchTransactionLogBody,
  ): Promise<
    ISuccessResponse<ITransactionsDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionLog, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  exportTransaction = async (
    payload: ISearchTransactionBody,
  ): Promise<IResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(transactionExportEP, payload);

      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  transactionChargeDispute = async (
    body: IDisputeTransactionBody,
    isChargeback: boolean,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(
        isChargeback
          ? transactionChargeChargebackEP
          : transactionChargeDisputeEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

class TransactionMockService {
  updateTransaction = async (): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  searchTransaction = async (): Promise<
    ISuccessResponse<ITransactionsDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ITransactionsDetails>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: {
                transactions: transactionsDataMock,
                TotalCount: 23,
              },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getSalvageSubscription = async (): Promise<
    ISuccessResponse<ISubscriptionSalvageResult> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ITransactionsDetails>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: {
                transactions: transactionsDataMock,
                TotalCount: 45,
              },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  voidTransaction = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  captureTransaction = async (
    body: ICaptureTransaction,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionCaptureEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  refundTransaction = async (): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getTransactionHistory = async (): Promise<
    ISuccessResponse<ITransactioHistoryDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ITransactioHistoryDetails>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: { Events: mockTransactionHistoryData },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };

  disputeTransaction = async (
    body: IDisputeTransactionBody,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionDisputeEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  transactionChargeDispute = async (
    body: IDisputeTransactionBody,
    isChargeback: boolean,
  ): Promise<
    ISuccessResponse<ICommonUpdateTransaction> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(
        isChargeback
          ? transactionChargeChargebackEP
          : transactionChargeDisputeEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  transactionLog = async (
    body: ISearchTransactionLogBody,
  ): Promise<
    ISuccessResponse<ITransactionsDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(transactionLog, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  exportTransaction = async (): Promise<
    | { data: string; status: number; message: string }
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<{
        data: string;
        status: number;
        message: string;
      }>(resolve => {
        setTimeout(() => {
          resolve({
            data: JSON.stringify(mockTransactionHistoryData),
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };
}

const globalTransactionService: TransactionService | TransactionMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new TransactionMockService()
    : new TransactionService();

export default globalTransactionService;
