import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Checkbox } from 'shared-components';
import 'components/transactions/style.scss';
import globalTransactionService from 'services/transactionService';

import {
  ISearchTransactionBody,
  ITransactionFormFilter,
  ITransactionTableFilter,
  ITransactionsData,
} from 'interface/transactionInterface';
import Pagination from 'components/common/pagination';
import { get } from 'lodash';
import { formattedDate, iterateHeadCellKeys } from 'utils/helper';
import SideFilter from 'components/transactions/transactionFilter/sideFilter';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import StatusBadge from 'components/common/statusBadge';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';

import { ReactComponent as PurchaseIcon } from 'assets/icons/purchase.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/icons/Group.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/master-card.svg';
import { ReactComponent as Upsell } from 'assets/icons/upsell-icon.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { ReactComponent as AmexIcon } from 'assets/icons/amex.svg';
import { ReactComponent as DiscoverIcon } from 'assets/icons/discover-card.svg';
import dayjs from 'dayjs';
import { Loader } from 'components/common/loader';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NestedTableComponent from 'components/transactions/transactionNestedTable/index';
import { getCustomerDetailsPath, getOrderDetialsPath } from 'router/constant';
import { useSearchParams } from 'react-router-dom';
import { IStateCustomers } from 'components/customers/customerDetails';
import Link from 'components/common/link';
import SearchFilter from 'components/common/searchFilter';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ITransactionsData) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}
export interface SearchProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface EventSearchProps {
  [key: string]: string[];
}
interface TransactionsProps {
  CustomerState?: IStateCustomers;
}

export const defaultTransactionFilterValues = {
  DateRange: null,
  StoreIDs: [],
  StorefrontIDs: [],
  TrafficChannel: [],
  Tags: [],
  ShopifyOrderNumber: null,
  BillingType: ['purchase', 'subscription', 'upsell'],
  StartTime: null,
  EndTime: null,
  MinCycle: '',
  MaxCycle: '',
  CustomerEmails: [],
  PhoneNumbers: [],
  FirstName: '',
  LastName: '',
  Status: [],
  DisputeAlerts: [],
  CCLast: '',
};

export default function Transactions({ CustomerState }: TransactionsProps) {
  const [search, setSearchValue] = useState<SearchProps>({});
  const [totalPage, settotalPage] = useState<number>(0);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });

  const headCells: readonly HeadCell[] = [
    {
      id: 'CreatedAt',
      label: 'Date',
      key: 'date',
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'PaymentProfile.FirstName',
      label: 'Customer Name',
      key: 'customer_name',
      cellRender: row => {
        return CustomerState ? (
          <div>
            {row?.Customer?.FirstName
              ? `${row?.Customer?.FirstName ?? ''} ${row?.Customer?.LastName ?? ''}`
              : `${row?.PaymentProfile?.FirstName ?? ''} ${row?.PaymentProfile?.LastName ?? ''}`}
          </div>
        ) : (
          <Link to={getCustomerDetailsPath(row?.Customer?.ID, row?.Store?.ID)}>
            {row.Customer.FirstName
              ? `${row.Customer.FirstName ?? ''} ${row.Customer.LastName ?? ''}`
              : `${row?.PaymentProfile?.FirstName ?? ''} ${row?.PaymentProfile?.LastName ?? ''}`}
          </Link>
        );
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'ExternalOrderID',
      label: 'Shopify Order Number',
      key: 'shopify_order_number',
      cellRender: row => {
        return (
          <Link to={getOrderDetialsPath(row.OrderID, row.Store.ID)}>
            {row.ExternalOrderID}
          </Link>
        );
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Channel.Name',
      label: 'Channel',
      key: 'channel',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
      cellRender: row => {
        return <p>{row?.Channel?.Name ? row?.Channel?.Name : '-'}</p>;
      },
    },
    {
      id: 'TransactionKind',
      label: 'Type',
      key: 'type',
      cellRender: row =>
        row.TransactionKind === 'purchase' ? (
          <>
            <Tooltip title={row.TransactionKind} arrow>
              <PurchaseIcon className="table-icons" />
            </Tooltip>
          </>
        ) : row.TransactionKind === 'subscription' ? (
          <Tooltip title={row.TransactionKind} arrow>
            <SubscriptionIcon className="table-icons" />
          </Tooltip>
        ) : (
          <Tooltip title={row.TransactionKind} arrow>
            <Upsell className="table-icons" />
          </Tooltip>
        ),
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Status',
      label: 'Status',
      key: 'status',
      cellRender: row => {
        return <StatusBadge status={row?.Status} />;
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'FailureReason',
      label: 'Failure Reason',
      key: 'failure_reason',
      cellRender: row => {
        return row.FailureReason ? row.FailureReason : '-';
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'dispute_notices',
      label: 'Risk',
      key: 'risk',
      cellRender: row => {
        return (
          <>{`${row?.DisputeAlerts?.length ? row?.DisputeAlerts.join(', ') : '-'}`}</>
        );
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'TrafficChannel',
      label: 'Traffic Channel',
      key: 'traffic_channel',
      cellRender: row => {
        return <p>{row?.TrafficChannel || '-'}</p>;
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'CycleNumber',
      label: 'Subscription Cycle',
      key: 'subscription_cycle',
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'payment_profile.cc_last4',
      label: 'CC Number',
      key: 'cc_number',
      cellRender: row => {
        const lastFourDigits = row?.PaymentProfile?.CCLast4 || '****';
        const cardBin = row?.PaymentProfile?.CardBin || '******';
        return `${cardBin.slice(0, 4)} ${cardBin.slice(4, 6)}** **** ${lastFourDigits.slice(-4)}`;
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'PaymentProfile.CardBrand',
      label: 'CC Brand',
      key: 'cc_brand',
      hide: false,
      cellRender: row => {
        let check = 0;
        let card = <></>;
        switch (row?.PaymentProfile?.CardBrand?.toLowerCase()) {
          case 'mastercard':
          case 'mc':
            card = <MasterCardIcon />;
            break;
          case 'visa':
            card = <VisaIcon />;
            break;
          case 'amex':
            card = <AmexIcon />;
            break;
          case 'discover':
          case 'disc':
            card = <DiscoverIcon />;
            break;
          default:
            check = 1;
            card = <span>{row?.PaymentProfile?.CardBrand}</span>;
            break;
        }
        return check === 0 ? <div className="card-brand">{card}</div> : card;
      },
      showSortIcon: true,
    },
    {
      id: 'Amount',
      label: 'Amount',
      key: 'amount',
      cellRender: row => {
        return <p>{row?.Amount}</p>;
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'Customer.PhoneNumber',
      label: 'Phone',
      key: 'phone',
      cellRender: row => {
        return <p>{row?.Customer?.PhoneNumber}</p>;
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'Customer.Email',
      label: 'Email',
      key: 'email',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'PaymentProfile.address1',
      label: 'Billing Address',
      key: 'billing_address',
      cellRender: row => {
        return (
          <>{`${row?.PaymentProfile?.Address1 ?? ''} ${row?.PaymentProfile?.Address2 ?? ''}`}</>
        );
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'PaymentProfile.address2',
      label: 'Shipping Address',
      key: 'shipping_address',
      cellRender: row => {
        return (
          <>{`${row?.PaymentProfile?.Address1 ?? ''} ${row?.PaymentProfile?.Address2 ?? ''}`}</>
        );
      },
      hide: false,
      showSortIcon: false,
    },

    {
      id: 'IPAddress',
      label: 'IP',
      key: 'ip',
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'CVVCode',
      label: 'CVV Code',
      key: 'cvv',
      hide: false,
      cellRender: row => {
        return <>{row.CVVCode}</>;
      },
      showSortIcon: false,
      showInSearch: true,
    },
    {
      id: 'AuthCode',
      label: 'Auth Code',
      key: 'auth',
      hide: false,
      cellRender: row => {
        return <>{row.AuthCode}</>;
      },
      showSortIcon: false,
      showInSearch: true,
    },
    {
      id: 'AVSCode',
      label: 'AVS Code',
      key: 'avs',
      hide: false,
      cellRender: row => {
        return <>{row.AVSCode}</>;
      },
      showSortIcon: false,
      showInSearch: true,
    },

    {
      id: 'tags',
      label: 'Tags',
      key: 'tags',
      cellRender: row => {
        return (
          <div className="tags_wrapper">
            {row.Tags?.map((tag, index) => {
              return (
                <span key={index} className="tag">
                  {tag}
                </span>
              );
            })}
          </div>
        );
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'Store.Name',
      label: 'Store',
      key: 'store',
      cellRender: row => {
        return <p>{`${row.Store.Name || 'N/A'}`}</p>;
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
  ];

  const filteredField = iterateHeadCellKeys([...headCells]);

  const [openRows, setOpenRows] = useState<boolean[]>();
  const disPatch = useAppDispatch();
  const rowsPerPage = 25;
  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const [sideFormFilter, setSideFormFilter] = useState<ITransactionFormFilter>({
    CCLast: searchParams.get('CCLast') || defaultTransactionFilterValues.CCLast,
    DisputeAlerts: searchParams.get('DisputeAlerts')
      ? JSON.parse(searchParams.get('DisputeAlerts') || '')
      : defaultTransactionFilterValues.DisputeAlerts,
    MinCycle:
      searchParams.get('MinCycle') || defaultTransactionFilterValues.MinCycle,
    MaxCycle:
      searchParams.get('MaxCycle') || defaultTransactionFilterValues.MaxCycle,
    DateRange:
      searchParams.get('DateRange') || defaultTransactionFilterValues.DateRange,
    StoreIDs: searchParams.get('StoreIDs')
      ? JSON.parse(searchParams.get('StoreIDs') || '')
      : defaultTransactionFilterValues.StoreIDs,
    StorefrontIDs: searchParams.get('StorefrontIDs')
      ? JSON.parse(searchParams.get('StorefrontIDs') || '')
      : defaultTransactionFilterValues.StorefrontIDs,
    TrafficChannel: searchParams.get('TrafficChannel')
      ? JSON.parse(searchParams.get('TrafficChannel') || '')
      : defaultTransactionFilterValues.TrafficChannel,
    Tags: searchParams.get('Tags')
      ? JSON.parse(searchParams.get('Tags') || '')
      : defaultTransactionFilterValues.Tags,
    CustomerEmails: searchParams.get('CustomerEmails')
      ? JSON.parse(searchParams.get('CustomerEmails') || '')
      : defaultTransactionFilterValues.CustomerEmails,
    PhoneNumbers: searchParams.get('PhoneNumbers')
      ? JSON.parse(searchParams.get('PhoneNumbers') || '')
      : defaultTransactionFilterValues.PhoneNumbers,
    FirstName: searchParams.get('FirstName')
      ? JSON.parse(searchParams.get('FirstName') || '')
      : defaultTransactionFilterValues.FirstName,
    LastName: searchParams.get('LastName')
      ? JSON.parse(searchParams.get('LastName') || '')
      : defaultTransactionFilterValues.LastName,
    ShopifyOrderNumber: defaultTransactionFilterValues.ShopifyOrderNumber,
    BillingType: defaultTransactionFilterValues.BillingType,
    StartTime: searchParams.get('StartTime')
      ? dayjs(searchParams.get('StartTime')).tz()
      : defaultTransactionFilterValues.StartTime,
    EndTime: searchParams.get('EndTime')
      ? dayjs(searchParams.get('EndTime')).tz()
      : defaultTransactionFilterValues.EndTime,
    Status: searchParams.get('Status')
      ? JSON.parse(searchParams.get('Status') || '')
      : [],
  });
  const [selected, setSelected] = useState<Map<string, ITransactionsData>>(
    new Map(),
  );
  const [filter, setFilter] = useState<ITransactionTableFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
  });

  const [transactionList, setTransactionList] = useState<ITransactionsData[]>(
    [],
  );

  const [page, setPage] = useState<number>(
    Number(searchParams.get('PageCount') || 1),
  );
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const { transactionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );

  const [headCellList, setHeadCellList] = useState<HeadCell[]>(() => [
    ...headCells.map(value => {
      return {
        ...value,
        hide: transactionColumnFilter?.includes(value.label),
      };
    }),
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(false);

  const handleRowClick = (transaction: ITransactionsData, checked: boolean) => {
    if (checked) {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(transaction.ID);
        return newSelected;
      });
    } else {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.set(transaction.ID, transaction);
        return newSelected;
      });
    }
  };
  const selectAll = (checked: boolean) => {
    if (checked) {
      const allRows = new Map(
        transactionList.map(row => {
          return [row.ID, row];
        }),
      );
      setSelected(allRows);
    } else {
      setSelected(new Map());
    }
  };

  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const filterSubmission = (formData: ITransactionFormFilter) => {
    setSideFormFilter(formData);
    setPage(1);
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      StoreIDs,
      StorefrontIDs,
      EndTime,
      StartTime,
      TrafficChannel,
      Tags,
      CustomerEmails,
      PhoneNumbers,
      FirstName,
      LastName,
      DisputeAlerts,
      DateRange,
      BillingType,
      Status,
      MinCycle,
      CCLast,
      MaxCycle,
    } = sideFormFilter;
    const statusValues = Status ? Status.map(temp => temp.value) : [];
    const { OrderBy, Descending } = filter;
    const payload: ISearchTransactionBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (DisputeAlerts?.length > 0) {
      payload.DisputeAlerts = DisputeAlerts;
    }
    if (CustomerState?.CustomerId) {
      payload.CustomerIDs = [CustomerState?.CustomerId];
    }
    if (BillingType?.length) {
      payload.TransactionKind = BillingType;
    }
    if (statusValues?.length) {
      payload.Status = statusValues;
    }
    if (storeIds?.length) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (StartTime) {
      payload.StartTime = StartTime.tz().startOf('day').format();
    }
    if (EndTime) {
      payload.EndTime = EndTime.tz().add(1, 'day').startOf('day').format();
    }
    if (TrafficChannel?.length) {
      payload.TrafficChannels = TrafficChannel;
    }
    if (CustomerEmails?.length) {
      payload.CustomerEmails = CustomerEmails;
    }
    if (PhoneNumbers?.length) {
      payload.PhoneNumbers = PhoneNumbers;
    }
    if (FirstName) {
      payload.FirstName = FirstName;
    }
    if (LastName) {
      payload.LastName = LastName;
    }
    if (Tags?.length) {
      payload.Tags = Tags;
    }
    if (MinCycle) {
      payload.MinCycle = Number(MinCycle);
    }
    if (MaxCycle) {
      payload.MaxCycle = Number(MaxCycle);
    }
    if (CCLast) {
      payload.CCLast4 = CCLast;
    }
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    if (isSetSearchParams) {
      setSearchParams(
        {
          StoreIDs: StoreIDs ? JSON.stringify(StoreIDs) : '',
          StorefrontIDs: StorefrontIDs ? JSON.stringify(StorefrontIDs) : '',
          Tags: Tags ? JSON.stringify(Tags) : '',
          CustomerEmails: CustomerEmails ? JSON.stringify(CustomerEmails) : '',
          PhoneNumbers: PhoneNumbers ? JSON.stringify(PhoneNumbers) : '',
          FirstName: FirstName ? JSON.stringify(FirstName) : '',
          LastName: LastName ? JSON.stringify(LastName) : '',
          StartTime: StartTime ? StartTime.tz().format() : '',
          EndTime: EndTime ? EndTime.tz().format() : '',
          TrafficChannel: TrafficChannel ? JSON.stringify(TrafficChannel) : '',
          Status: Status ? JSON.stringify(Status) : '',
          DisputeAlerts: DisputeAlerts ? JSON.stringify(DisputeAlerts) : '',
          DateRange: DateRange || '',
          PageCount: String(page),
          MinCycle: MinCycle,
          MaxCycle: MaxCycle,
          CCLast: CCLast,
          Descending: String(Descending),
          OrderBy: OrderBy,
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };

  const downloadCsvFile = async () => {
    const payload = generatePayload(false);
    payload.Page = 0;
    payload.Limit = totalPage || 25;
    const response = await globalTransactionService.exportTransaction(payload);
    if (response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'transaction.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const searchTransactionList = async () => {
    setLoading(true);
    const payload = generatePayload();
    const response = await globalTransactionService.searchTransaction({
      ...payload,
      Page: page - 1,
      Limit: limit,
    });
    if (response?.status === 200) {
      if (response?.data?.Result?.length > 0) {
        setTransactionList(response?.data?.Result);
        settotalPage(response?.data?.TotalCount);
        setIsNextBtnDisabled(response?.data?.Result?.length < limit);
        setOpenRows(Array(response?.data?.Result.length).fill(false));
      } else {
        setIsNextBtnDisabled(true);
        settotalPage(0);
        setTransactionList([]);
      }
    } else {
      setTransactionList([]);
      settotalPage(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchTransactionList();
  }, [
    sideFormFilter,
    CustomerState?.CustomerId,
    page,
    filter,
    search,
    limit,
    storeIds,
    timeZone,
  ]);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
      disPatch(setCurrentFilter(''));
    };
  }, []);

  return (
    <div className="transaction_container">
      <div className="transaction_top_container">
        <div className="transaction_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer
          className="transactions_table_container Common_Table"
          ref={ref}>
          <Table
            className="transactions_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                <TableCell className="table_header_cell" />
                <TableCell className="table_header_cell">
                  <div className="flex-row">
                    <Checkbox
                      checked={
                        transactionList.length > 0 &&
                        selected.size === transactionList.length
                      }
                      onChange={(_, checked) => selectAll(checked)}
                      inputProps={{
                        'aria-labelledby': 'enhanced-table-checkbox-all',
                      }}
                    />
                  </div>
                </TableCell>
                {headCellList?.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {transactionList.length > 0 ? (
                transactionList.map((row, index) => {
                  const isItemSelected = !!selected.get(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <>
                      <TableRow
                        hover
                        className="table_row"
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.ID}
                        selected={isItemSelected}>
                        <TableCell className="table_cell">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            className="expand-row"
                            onClick={() => handleRowToggle(index)}>
                            {(openRows ? openRows[index] : false) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell padding="checkbox" className="table_cell">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onChange={() => handleRowClick(row, isItemSelected)}
                          />
                        </TableCell>
                        {headCellList.map(headCell => {
                          if (headCell.hide === true) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {(openRows ? openRows[index] : false) && (
                        <TableRow className="row_collapsed">
                          <TableCell
                            className="table_border_none nested-table-cell"
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={9}>
                            <Collapse
                              in={openRows ? openRows[index] : false}
                              timeout="auto"
                              unmountOnExit>
                              <div style={{ width: width ? width : 'auto' }}>
                                <NestedTableComponent row={row} />
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          perPage={page}
          totalData={totalPage}
          onRowsPerChange={val => {
            setLimit(val);
          }}
          limit={limit}
          currentPage={page}
          isNextDisabled={isNextBtnDisabled}
          onHandleChange={page => {
            setSelected(new Map());
            setPage(page);
          }}
        />
        <Loader loading={loading} />
      </div>
      <SideFilter
        headCellList={headCellList}
        setHeadCellList={setHeadCellList}
        selected={selected}
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        setLoading={setLoading}
        resetSelected={() => setSelected(new Map())}
        setTransactionList={setTransactionList}
        searchTransactionList={searchTransactionList}
        exportList={downloadCsvFile}
      />
    </div>
  );
}
