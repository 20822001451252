import { ReactNode, useEffect, useState } from 'react';
import 'components/SubscriptionManagement/style.scss';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import Pagination from 'components/common/pagination';
import { Checkbox } from 'shared-components';
import SideFilter from 'components/SubscriptionManagement/SideFilter';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import {
  ISubscrptionManagement,
  ISubscrptionManagementSearch,
  SideFormFilterSubscription,
} from 'interface/subscriptionManagementInterface';
import { get } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setPageConfig } from 'redux-setup/slices/pageConfigSlice';
import { Loader } from 'components/common/loader';
import { ISubscriptionTableFilter } from 'interface/subscriptionManagementInterface';
import {
  amountFormatter,
  auth_period_unit,
  downloadCsv,
  formattedDate,
  iterateHeadCellKeys,
} from 'utils/helper';
import dayjs from 'dayjs';
import { getCustomerDetailsPath } from 'router/constant';
import { useSearchParams } from 'react-router-dom';
import StatusBadge from 'components/common/statusBadge';
import Link from 'components/common/link';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';
import SearchFilter from 'components/common/searchFilter';
import { SearchProps } from 'components/transactions';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ISubscrptionManagement) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

const headCellList: readonly HeadCell[] = [
  {
    id: 'Customer.FirstName',
    label: 'Customer Name',
    key: 'customer_name',
    cellRender: row => {
      return (
        <Link to={getCustomerDetailsPath(row.Customer.ID, row?.Store.ID)}>
          {row.Customer.FirstName + ' ' + row.Customer.LastName}
        </Link>
      );
    },
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'Customer.Email',
    label: 'Email',
    key: 'email',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'Store.Name',
    label: 'Store',
    key: 'store',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'Channel.Name',
    label: 'Channel',
    key: 'channel',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
    cellRender: row => {
      return <p>{row?.Channel?.Name ? row?.Channel?.Name : '-'}</p>;
    },
  },
  {
    id: 'purchase.external_order_number',
    label: 'Shopify Order',
    key: 'shopify_order',
    hide: false,
    showSortIcon: false,
  },
  {
    id: 'Amount',
    label: 'Amount',
    key: 'amount',
    hide: false,
    cellRender: row => {
      return <span>{amountFormatter.format(parseFloat(row.Amount))}</span>;
    },
    showSortIcon: false,
  },
  {
    id: 'AuthPeriod',
    key: 'auth_period',
    label: 'Auth Period',
    hide: false,
    cellRender: row => {
      return (
        <>
          {row?.AuthPeriod}{' '}
          {
            auth_period_unit?.find(value => value.value === row?.AuthPeriodUnit)
              ?.label
          }
        </>
      );
    },
  },
  {
    id: 'Purchase.CreatedAt',
    label: 'Date Acquired',
    key: 'date_acquired',
    hide: false,
    cellRender: row =>
      row?.CreatedAt ? (
        <Tooltip placement="top" title={formattedDate(row.CreatedAt)} arrow>
          <span>{formattedDate(row.CreatedAt, true)}</span>
        </Tooltip>
      ) : (
        '-'
      ),
    showSortIcon: true,
  },
  {
    id: 'Status',
    label: 'Status',
    key: 'status',
    hide: false,
    cellRender: row => {
      return <StatusBadge status={row.Status} />;
    },
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'NextBillingDate',
    label: 'Next Billing Date',
    key: 'next_billing_date',
    cellRender: row =>
      row.Status === SubscriptionStatus.subscriptionStatusCanceled ? (
        '-'
      ) : (
        <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
          <span>{formattedDate(row?.NextBillingDate, true)}</span>
        </Tooltip>
      ),
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'CurrentCycle',
    label: 'Cycle Completed',
    key: 'cycle_completed',
    inputType: 'number',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
  },
];

export const defaultSubscriptionFilterValues = {
  MaxAmount: '',
  MaxCycle: '',
  MinAmount: '',
  MinCycle: '',
  CustomerEmails: [],
  PhoneNumbers: [],
  FirstName: '',
  LastName: '',
  StartTime: null,
  EndTime: null,
  NextBillingDateEnd: null,
  NextBillingDateStart: null,
  Status: [],
  StoreIDs: [],
};

const rowsPerPage = 25;

const filteredField = iterateHeadCellKeys([...headCellList]);
export default function SubscriptionManagement() {
  const [search, setSearchValue] = useState<SearchProps>({});
  const { subscriptionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );
  const [totalPage, settotalPage] = useState<number>(0);
  const [headCell, setHeadCell] = useState<HeadCell[]>([
    ...headCellList.map(value => {
      return {
        ...value,
        hide: subscriptionColumnFilter?.includes(value.label),
      };
    }),
  ]);
  const [subscriptionManagementData, setSubscriptionManagementData] = useState<
    ISubscrptionManagement[]
  >([]);
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const [sideFormFilterSubscription, setSideFormFilterSubscription] =
    useState<SideFormFilterSubscription>({
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : defaultSubscriptionFilterValues.StartTime,
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : defaultSubscriptionFilterValues.EndTime,
      MaxAmount:
        searchParams.get('MaxAmount') ||
        defaultSubscriptionFilterValues.MaxAmount,
      MaxCycle: searchParams.get('MaxCycle')
        ? JSON.parse(searchParams.get('MaxCycle') || '')
        : defaultSubscriptionFilterValues.MaxCycle,
      CustomerEmails: searchParams.get('CustomerEmails')
        ? JSON.parse(searchParams.get('CustomerEmails') || '')
        : defaultSubscriptionFilterValues.CustomerEmails,
      PhoneNumbers: searchParams.get('PhoneNumbers')
        ? JSON.parse(searchParams.get('PhoneNumbers') || '')
        : defaultSubscriptionFilterValues.PhoneNumbers,
      FirstName: searchParams.get('FirstName')
        ? JSON.parse(searchParams.get('FirstName') || '')
        : defaultSubscriptionFilterValues.FirstName,
      LastName: searchParams.get('LastName')
        ? JSON.parse(searchParams.get('LastName') || '')
        : defaultSubscriptionFilterValues.LastName,
      MinAmount:
        searchParams.get('MinAmount') ||
        defaultSubscriptionFilterValues.MinAmount,
      MinCycle: searchParams.get('MinCycle')
        ? JSON.parse(searchParams.get('MinCycle') || '')
        : defaultSubscriptionFilterValues.MinCycle,
      NextBillingDateEnd: searchParams.get('NextBillingDateEnd')
        ? dayjs(searchParams.get('NextBillingDateEnd')).tz()
        : defaultSubscriptionFilterValues.NextBillingDateEnd,
      NextBillingDateStart: searchParams.get('NextBillingDateStart')
        ? dayjs(searchParams.get('NextBillingDateStart')).tz()
        : defaultSubscriptionFilterValues.NextBillingDateStart,
      Status: searchParams.get('Status')
        ? JSON.parse(searchParams.get('Status') || '')
        : defaultSubscriptionFilterValues.Status,
      StoreIDs: searchParams.get('StoreIDs')
        ? JSON.parse(searchParams.get('StoreIDs') || '')
        : defaultSubscriptionFilterValues.StoreIDs,
    });
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<Map<string, ISubscrptionManagement>>(
    new Map(),
  );

  const [page, setPage] = useState<number>(
    Number(searchParams.get('PageCount') || 1),
  );
  const dispatch = useAppDispatch();
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(false);
  const [filter, setFilter] = useState<ISubscriptionTableFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
  });
  const handleRowChecked = (
    subscriptionManagement: ISubscrptionManagement,
    checked: boolean,
  ) => {
    if (checked) {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(subscriptionManagement.Customer.ID);
        return newSelected;
      });
    } else {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.set(
          subscriptionManagement.Customer.ID,
          subscriptionManagement,
        );
        return newSelected;
      });
    }
  };

  const selectAll = (checked: boolean) => {
    if (checked) {
      const allRows = new Map(
        subscriptionManagementData.map(row => {
          return [row.Customer?.ID, row];
        }),
      );
      setSelected(allRows);
    } else {
      setSelected(new Map());
    }
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      StoreIDs,
      NextBillingDateStart,
      NextBillingDateEnd,
      Status,
      MinCycle,
      MaxCycle,
      MaxAmount,
      MinAmount,
      EndTime,
      CustomerEmails,
      PhoneNumbers,
      FirstName,
      LastName,
      StartTime,
    } = sideFormFilterSubscription;
    const statusValues = Status ? Status.map(temp => temp.value) : null;
    const { Descending, OrderBy } = filter;
    const payload: ISubscrptionManagementSearch = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (isSetSearchParams) {
      setSearchParams(
        {
          EndTime: EndTime ? EndTime.tz().format() : '',
          StartTime: StartTime ? StartTime.tz().format() : '',
          NextBillingDateEnd: NextBillingDateEnd
            ? NextBillingDateEnd.tz().format()
            : '',
          NextBillingDateStart: NextBillingDateStart
            ? NextBillingDateStart.tz().format()
            : '',
          MinAmount: MinAmount || '',
          MaxAmount: MaxAmount || '',
          Status: Status ? JSON.stringify(Status) : '',
          StoreIDs: StoreIDs ? JSON.stringify(StoreIDs) : '',
          MinCycle: MinCycle ? JSON.stringify(MinCycle) : '',
          MaxCycle: MaxCycle ? JSON.stringify(MaxCycle) : '',
          CustomerEmails: CustomerEmails ? JSON.stringify(CustomerEmails) : '',
          PhoneNumbers: PhoneNumbers ? JSON.stringify(PhoneNumbers) : '',
          FirstName: FirstName ? JSON.stringify(FirstName) : '',
          LastName: LastName ? JSON.stringify(LastName) : '',
          PageCount: String(page),
          OrderBy: OrderBy,
          Descending: String(Descending),
          Limit: String(limit),
        },
        { replace: true },
      );
    }

    if (storeIds?.length) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (StartTime) {
      payload.StartTime = StartTime.tz().startOf('day').format();
    }
    if (EndTime) {
      payload.EndTime = EndTime.tz().add(1, 'day').startOf('day').format();
    }
    if (statusValues?.length) {
      payload.Status = statusValues;
    }
    if (MinCycle) {
      payload.MinCycle = Number(MinCycle);
    }
    if (MaxCycle) {
      payload.MaxCycle = Number(MaxCycle);
    }
    if (MaxAmount) {
      payload.MaxAmount = MaxAmount;
    }
    if (MinAmount) {
      payload.MinAmount = MinAmount;
    }
    if (NextBillingDateEnd) {
      payload.NextBillingDateEnd = NextBillingDateEnd.tz()
        .add(1, 'day')
        .startOf('day')
        .format();
    }
    if (NextBillingDateStart) {
      payload.NextBillingDateStart = NextBillingDateStart.tz()
        .startOf('day')
        .format();
    }
    if (CustomerEmails?.length) {
      payload.CustomerEmails = CustomerEmails;
    }
    if (PhoneNumbers?.length) {
      payload.PhoneNumbers = PhoneNumbers;
    }
    if (FirstName) {
      payload.FirstName = FirstName;
    }
    if (LastName) {
      payload.LastName = LastName;
    }
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    return payload;
  };
  const downloadCsvFile = async () => {
    // need to pass false to generate payload without setting search params
    const payload = generatePayload(false);
    payload.Page = 0;
    payload.Limit = totalPage || 25;
    const res =
      await globalSubscriptionManagementService.exportSubscriptionData(payload);
    if (res?.status === 200) {
      downloadCsv(res.data, 'Subscription');
    }
  };

  const searchOrderList = async () => {
    setLoading(true);
    const payload = generatePayload();
    const res =
      await globalSubscriptionManagementService.getSubscriptionData(payload);
    setLoading(false);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setSubscriptionManagementData(res?.data?.Result);
        settotalPage(res.data.TotalCount);
        setIsNextBtnDisabled(res?.data?.Result?.length < limit);
      } else {
        setIsNextBtnDisabled(true);
        settotalPage(0);
        setSubscriptionManagementData([]);
      }
    } else {
      settotalPage(0);
      setSubscriptionManagementData([]);
    }
  };

  const filterSubmission = (formData: SideFormFilterSubscription) => {
    setSideFormFilterSubscription(formData);
    setPage(1);
  };

  useEffect(() => {
    searchOrderList();
  }, [
    sideFormFilterSubscription,
    filter,
    page,
    limit,
    search,
    storeIds,
    timeZone,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);

  return (
    <>
      <div className="subscription_management_wrapper">
        <div className="subscription_management">
          <div className="subscription_search_container">
            <SearchFilter
              filteredField={filteredField}
              setSearchValue={data => {
                setPage(1);
                setSearchValue(data);
              }}
              searchValue={search}
            />
          </div>
          <TableContainer className="Common_Table">
            <Table
              className="subscription_management_table"
              aria-labelledby="tableTitle"
              stickyHeader>
              <TableHead className="table_header">
                <TableRow>
                  <TableCell className="table_header_cell">
                    <div className="flex-row">
                      <Checkbox
                        checked={
                          subscriptionManagementData.length > 0 &&
                          selected.size === subscriptionManagementData.length
                        }
                        onChange={(_, checked) => selectAll(checked)}
                      />
                    </div>
                  </TableCell>
                  {headCell.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className="table_header_cell"
                        key={headCell.key}>
                        {headCell?.showSortIcon ? (
                          <TableSortLabel
                            className="header_text"
                            active={filter.OrderBy === headCell.id}
                            direction={
                              filter?.OrderBy === headCell.id
                                ? filter.Descending
                                  ? 'desc'
                                  : 'asc'
                                : 'asc'
                            }
                            onClick={() => sortHandler(headCell.id)}>
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {subscriptionManagementData.length > 0 ? (
                  subscriptionManagementData.map((row, index) => {
                    const isItemSelected = !!selected.get(row.Customer.ID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        className="table_row"
                        role="checkbox"
                        tabIndex={-1}
                        key={row.ID}>
                        <TableCell padding="checkbox" className="table_cell">
                          <Checkbox
                            checked={isItemSelected}
                            aria-checked={isItemSelected}
                            onChange={() =>
                              handleRowChecked(row, isItemSelected)
                            }
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        {headCell.map(headCell => {
                          if (headCell.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              <>
                                {headCell.cellRender
                                  ? headCell.cellRender(row)
                                  : get(row, headCell?.id)}
                              </>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <div className="no-data-row">No data found</div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            limit={limit}
            onRowsPerChange={val => {
              setLimit(val);
            }}
            totalData={totalPage}
            perPage={page}
            currentPage={page}
            isNextDisabled={isNextBtnDisabled}
            onHandleChange={page => {
              setSelected(new Map());
              setPage(page);
            }}
          />
          <Loader loading={loading} />
        </div>
        <SideFilter
          headCell={headCell}
          setHeadCell={setHeadCell}
          sideFormFilterSubscription={sideFormFilterSubscription}
          filterSubmission={filterSubmission}
          selected={selected}
          setLoading={setLoading}
          resetSelected={() => setSelected(new Map())}
          searchOrderList={searchOrderList}
          setSubscriptionManagementData={setSubscriptionManagementData}
          exportList={downloadCsvFile}
        />
      </div>
    </>
  );
}
