import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { formatPrice } from '../../../page-builder/utils/formatPrice';
import { isZeroOrEmpty } from '../../../page-builder/utils/isZeroOrEmpty';
import { ReduxSelectorProps } from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';

export type OrderDetailsProps = MarginProps;

export const OrderDetails = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<OrderDetailsProps> => {
  return {
    label: (
      <CustomTooltip title="OrderDetails">
        <span>Order Details</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...marginFields,
    },
    defaultProps: {
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin }) => {
      const cartData = useAppSelector(state => state.cart.cartData);
      const selectedShippingMethod = useAppSelector(
        state => state.checkout.selectedShippingMethod,
      );

      const isFree =
        cartData &&
        cartData?.LineItems?.length > 0 &&
        selectedShippingMethod?.Price === '0.00' &&
        cartData?.Fees?.shipping?.OriginalCost === '0.00';

      return (
        <>
          <dl
            className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} space-y-6 border-t border-gray-200 text-sm font-medium text-gray-900 lg:block`}>
            <div className="flex mt-6 items-center justify-between">
              <dt className="text-gray-600">Subtotal</dt>
              <dd>${formatPrice(cartData?.SubtotalAmount || '')}</dd>
            </div>

            {cartData?.DiscountAmount &&
              !isZeroOrEmpty(cartData?.DiscountAmount) && (
                <div className="flex items-center justify-between">
                  <dt className="text-gray-700 font-normal text-sm">
                    Discount Total
                  </dt>
                  <dd>${formatPrice(cartData?.DiscountAmount)}</dd>
                </div>
              )}

            <div className="flex items-center justify-between">
              <dt className="text-gray-700 font-normal text-sm">Shipping</dt>
              <div className="flex">
                {cartData?.ShippingRequired === true &&
                  cartData?.LineItems?.length > 0 &&
                  selectedShippingMethod?.Price !== '0.00' && (
                    <>
                      <dd
                        className={
                          cartData?.Fees?.shipping?.DiscountedCost
                            ? 'line-through text-gray-400'
                            : ''
                        }>
                        $
                        {formatPrice(
                          selectedShippingMethod?.Price ||
                            cartData?.Fees?.shipping?.OriginalCost,
                        )}
                      </dd>
                      {cartData?.Fees?.shipping?.DiscountedCost && (
                        <dd className="ml-3 font-normal text-sm">
                          $
                          {formatPrice(
                            (selectedShippingMethod?.DiscountedPrice ||
                              cartData?.Fees?.shipping?.DiscountedCost) ??
                              '',
                          )}
                        </dd>
                      )}
                    </>
                  )}
                {isFree && <dd>Free</dd>}
              </div>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base font-semibold">Grand Total</dt>

              <dd className="text-base font-semibold">
                $
                {!cartData ||
                !cartData.LineItems ||
                cartData.LineItems.length === 0
                  ? formatPrice('0.0')
                  : formatPrice(cartData?.TotalAmount)}
              </dd>
            </div>
          </dl>
        </>
      );
    },
  };
};
