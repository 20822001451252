import moment from 'moment';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { dateRange } from 'components/dashboard/constant';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import globalUserService from 'services/userService';
import { IUserPermission } from 'interface/settingsInterface';
import { store } from 'redux-setup/store';
import { IPages } from 'interface/userInterface';
import { Permission } from 'router/constant';
import { HeadCell as HeadCellTransactions } from 'components/transactions';
import { HeadCell as HeadCellTransactionLog } from 'components/transactionLog';
import { HeadCell as HeadCellSubscription } from 'components/SubscriptionManagement';
import { HeadCell as HeadCellSubscriptionEvents } from 'components/subscriptionEvents';
import { HeadCell as HeadCellCarts } from 'components/abandonedCarts';
import { HeadCell as HeadCellOrders } from 'components/orders';
import { HeadCell as HeadCellCustomers } from 'components/customers';
import { setPagesPermission } from 'redux-setup/slices/permissionSlice';
import { DataObject } from 'interface/commonInterface';
import { currentEnvironment, userPermissionKeys } from './constants';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);
import { HeadCellAdmin } from 'components/admins';
import featureToggles from '../feature-toggles.json';
import _ from 'lodash';
import { decodeUser } from 'services/tokenService';
dayjs.extend(utc);
dayjs.extend(timezone);

export const listMyPermissions = async () => {
  const res = await globalUserService.getMyPermissions();
  if (res?.data) {
    const permission: IUserPermission[] = res.data?.StorePermissions;
    const maps = new Map<string, string>();
    if (res?.data?.SuperAdmin) {
      store.dispatch(
        setPagesPermission({
          order: userPermissionKeys.manage,
          customer: userPermissionKeys.manage,
          transaction: userPermissionKeys.manage,
          subscription: userPermissionKeys.manage,
          events: userPermissionKeys.manage,
          superAdmin: res?.data?.SuperAdmin,
        }),
      );
    } else {
      const check = permission?.some(value => {
        return value.Permissions.some(val => {
          const arr = val.split(':');
          if (
            arr?.[0]?.toLowerCase().trim() === userPermissionKeys.manage &&
            arr?.[1]?.toLocaleLowerCase().trim() === userPermissionKeys.all
          ) {
            store.dispatch(
              setPagesPermission({
                order: userPermissionKeys.manage,
                customer: userPermissionKeys.manage,
                transaction: userPermissionKeys.manage,
                subscription: userPermissionKeys.manage,
                events: res?.data?.SuperAdmin ? userPermissionKeys.manage : '',
                superAdmin: res?.data?.SuperAdmin,
              }),
            );
            return true;
          } else if (
            arr?.[0]?.toLowerCase().trim() === userPermissionKeys.view &&
            arr?.[1]?.toLowerCase().trim() === userPermissionKeys.all
          ) {
            const pages = [
              Permission.ORDER,
              Permission.CUSTOMER,
              Permission.TRANSACTION,
              Permission.SUBSCRIPTION,
            ];
            pages.forEach(val => {
              if (!maps.get(val)) maps.set(val, arr[0]);
            });
          } else {
            if (maps.get(arr?.[1]) !== userPermissionKeys.manage)
              maps.set(arr?.[1], arr?.[0]);
          }
          return false;
        });
      });
      if (!check) {
        const value: IPages = {};
        maps.forEach((values, keys) => {
          value[keys as keyof IPages] = values;
        });
        store.dispatch(
          setPagesPermission({
            ...value,
            superAdmin: res?.data?.SuperAdmin,
          }),
        );
      }
    }
  }
};

export const formattedDate = (
  dateString: string,
  isOnlyDate: boolean = false,
) => {
  if (!dateString) {
    return '';
  }

  const originalDate = dayjs(dateString).tz();
  const year = originalDate.get('year');
  const month = (originalDate.get('month') + 1).toString().padStart(2, '0');
  const day = originalDate.get('date').toString().padStart(2, '0');
  const hours = originalDate.get('hours').toString().padStart(2, '0');
  const minutes = originalDate.get('minutes').toString().padStart(2, '0');
  const ampm = originalDate.get('hours') < 12 ? 'AM' : 'PM';
  const formattedDate = isOnlyDate
    ? `${year}-${month}-${day}`
    : `${year}-${month}-${day} ${Number(hours) > 12 ? Number(hours) - 12 : hours}:${minutes} ${ampm}`;

  return formattedDate;
};

export const percentage = (
  partialValue: number,
  totalValue: number,
  roundResult = false,
  toFixed = 2,
) => {
  const result = (100 * partialValue) / totalValue;
  if (isNaN(result)) {
    return 0;
  }
  if (roundResult) {
    return Math.round(result);
  }
  if (toFixed) {
    return Number(result.toFixed(toFixed));
  }
  return result;
};

export const getValueByPercentage = (value: number, percentage: number) => {
  return (value * percentage) / 100;
};

export const getPercentageByValue = (value: number, totalValue: number) => {
  if (totalValue === 0) {
    return 0;
  }
  return Number(Number((value * 100) / totalValue).toFixed(2));
};

export default function formatNumber(num: number, precision = 0) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return precision ? num.toFixed(precision) : num;
}

export function numberWithCommas(x: number) {
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
// Function to get week number, month, and year
function getWeekMonthYear(d: Date): {
  weekOfMonth: number;
  month: number;
  year: number;
} {
  const startOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
  const days = Math.floor(
    (d.getTime() - startOfMonth.getTime()) / (24 * 60 * 60 * 1000),
  );
  const weekOfMonth = Math.ceil((days + startOfMonth.getDay() + 1) / 7);
  const month = d.getMonth() + 1; // getMonth() is zero-based
  return {
    weekOfMonth,
    month,
    year: Number(d.getFullYear().toString().slice(-2)),
  };
}
// For groupAndSort function sample input and expected return value are in helper.test.ts
// Based on the second parameter(moment format) the Xaxis label will be generated

type ChartDataObject<T> = {
  dateforSorting: string;
  chartdata: T[];
};

type ObjectsByKey<T> = {
  [key: string]: ChartDataObject<T>;
};

export const groupAndSort = <T>(
  grouped: { [_k: string]: T[] },
  format: string,
  isDashBoard: boolean = false,
): [string, T[]][] => {
  let objectsByKey: ObjectsByKey<T> = {};
  if (format === 'Week') {
    objectsByKey = Object.entries(grouped).reduce(
      (
        def: { [_k: string]: { dateforSorting: string; chartdata: T[] } },
        [date, data],
      ) => {
        const { weekOfMonth, month, year } = getWeekMonthYear(new Date(date));
        const uniqueKey = `${weekOfMonth}W ${getMonthNameFromNumber(month)} ${year}`;
        const preData = def?.[uniqueKey]?.chartdata;
        return {
          ...def,
          [uniqueKey]: {
            dateforSorting: date,
            chartdata: preData ? [...preData, ...data] : [...data],
          },
        };
      },
      {},
    );
  } else {
    objectsByKey = Object.entries(grouped).reduce(
      (
        def: { [_k: string]: { dateforSorting: string; chartdata: T[] } },
        [date, data],
      ) => {
        const uniqueKey = dayjs(date)
          .tz(store?.getState()?.pathConfig?.timeZone)
          .format(format);
        const preData = def?.[uniqueKey]?.chartdata;
        return {
          ...def,
          [uniqueKey]: {
            dateforSorting: date,
            chartdata: preData ? [...preData, ...data] : [...data],
          },
        };
      },
      {},
    );
  }

  return Object.entries(objectsByKey)
    .sort((a, b) => {
      return (
        new Date(a[1].dateforSorting).getTime() -
        new Date(b[1].dateforSorting).getTime()
      );
    })
    .map((data, i) => {
      return [
        format === 'W'
          ? `week ${i}`
          : isDashBoard
            ? getLabelsForTooltip(format, data?.[1]?.dateforSorting, data?.[0])
            : data?.[0],
        data[1]?.chartdata,
      ];
    });
};

const getLabelsForTooltip = (
  format: string,
  date: string,
  labelInxaxis: string,
) => {
  if (format === 'MMM-YY') {
    return labelInxaxis + ':' + labelInxaxis;
  }
  if (format === 'Week') {
    return labelInxaxis + ':' + moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  if (format === 'D h A') {
    const formattedLabel = labelInxaxis.split(' ');
    const tooltipLabel =
      formattedLabel?.[1] +
      ' ' +
      formattedLabel?.[2] +
      ' ' +
      dayjs(date).format('YYYY-MM-DD');
    return formattedLabel[1] + ' ' + formattedLabel[2] + ':' + tooltipLabel;
  }
  if (format === 'h A') {
    return labelInxaxis + ' ' + dayjs(date).format('YYYY-MM-DD ');
  }
  if (format === 'MMM' || format === 'MM/DD') {
    return labelInxaxis + ':' + labelInxaxis + '/' + dayjs(date).format('YYYY');
  }

  return '';
};

export const emptyStats = {
  transaction: {
    Amount: 0,
    RefundedAmount: 0,
    Count: 0,
    SourceCount: 0,
    ChargeType: '',
    CardBrand: '',
    Status: '',
    DisputeAlerts: [],
    CycleNumber: 0,
    Date: '',
  },
  subscription: {
    NewSubscriptions: 0,
    CanceledSubscriptions: 0,
    SalvagingSubscriptions: 0,
    RecoveredSubscriptions: 0,
    RenewedSubscriptions: 0,
    Date: '',
  },
  uniqueTransaction: {
    ChargeType: '',
    Count: 0,
    Status: '',
    Date: '',
    CycleNumber: 0,
  },
};
const addEmptyStats = <T>(grouped: { [_k: string]: T[] }, emptyStat: T) => {
  const date = Object.keys(grouped)?.[0];
  const previousPoint = dayjs(date).subtract(1, 'hour').format();
  const nextPoint = dayjs(date).add(1, 'hour').format();
  grouped[previousPoint] = [
    {
      ...emptyStat,
      Date: previousPoint,
    } as T,
  ];
  grouped[nextPoint] = [
    {
      ...emptyStat,
      Date: nextPoint,
    } as T,
  ];
};

export const groupPoints = <T>(
  groupedTansaction: {
    [_k: string]: T[];
  },
  emptyStat?: T,
  isNewDAshboard: boolean = false,
  binwidth?: string,
): [string, T[]][] => {
  const grouped = groupedTansaction;
  const sorted = Object.entries(grouped).sort((a, b) => {
    return new Date(a[0]).getTime() - new Date(b[0]).getTime();
  });
  const startMoment = moment(sorted[0][0]);
  const endMoment = moment(sorted[sorted.length - 1][0]);
  const diffInDays = endMoment.diff(startMoment, 'days');
  const totalPoints = Object.entries(grouped).length;
  if (isNewDAshboard) {
    if (binwidth === 'week') {
      // The points are grouped based on "weeks"
      return groupAndSort(grouped, 'Week', isNewDAshboard);
    }
    if (binwidth === 'day') {
      // The points are grouped based on "months"
      return groupAndSort(grouped, 'MM/DD', isNewDAshboard);
    }
    if (binwidth === 'month') {
      return groupAndSort(grouped, 'MMM-YY', isNewDAshboard);
    }
    if (binwidth === 'hour') {
      return groupAndSort(grouped, 'D h A', isNewDAshboard);
    }
  }

  if (diffInDays < 1) {
    if (emptyStat && totalPoints === 1) {
      addEmptyStats(grouped, emptyStat); // pushing empty stats if only one point is available
    }
    // lesser than to one day, The points are grouped based on "hours"
    return groupAndSort(grouped, 'h A', isNewDAshboard);
  }
  if (diffInDays < 31) {
    // lesser than a month, The points are grouped based on "days in week"
    return groupAndSort(grouped, 'MM/DD', isNewDAshboard);
  }
  if (diffInDays < 365) {
    // lesser than a year
    if (totalPoints <= 10) {
      // lesser than a year and points are lesser than 11, The points are grouped based on "days in month"
      return groupAndSort(grouped, 'MM/DD', isNewDAshboard);
    }
    // lesser than a year, The points are grouped based on "months"
    return groupAndSort(grouped, 'MMM-YY', isNewDAshboard);
  }
  // The points are grouped based on "year"
  return groupAndSort(grouped, 'YYYY', isNewDAshboard);
};
export function formatPhoneNumber(phoneNumber: string) {
  const number = parsePhoneNumberFromString(phoneNumber);
  return number?.formatInternational();
}
export const detectBooleanValue = (value: string): boolean => {
  if (['true', 'false'].includes(value)) {
    return JSON.parse(value);
  }
  return false;
};

export const jsonToQueryString = (json: {
  [key: string]: string | number | boolean;
}): string => {
  return `?${Object.keys(json)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
    .join('&')}`;
};

export const queryStringToObject = (url: string): object => {
  return Object.fromEntries([...new URLSearchParams(url.split('?')[1])]);
};

const dateRangeValues = {
  [dateRange.thisWeek]: {
    // start and end time of the current week
    start_time: dayjs().startOf('week').tz(),
    end_time: dayjs().tz(),
  },
  [dateRange.thisMonth]: {
    // start and end time of the current month
    start_time: dayjs().startOf('month').tz(),
    end_time: dayjs().tz(),
  },
  [dateRange.thisYear]: {
    // start and end time of the current week
    start_time: dayjs().startOf('year').tz(),
    end_time: dayjs().tz(),
  },
  [dateRange.lastYear]: {
    // start and end time of the last year
    start_time: dayjs().subtract(1, 'year').startOf('year').tz(),
    end_time: dayjs().subtract(1, 'year').endOf('year').tz(),
  },
  [dateRange.today]: {
    start_time: dayjs().tz(),
    end_time: dayjs().tz(),
  },
};

export const getDateFilter = (range: string) => {
  // returns start and end date based on range parameter
  return (
    dateRangeValues[range] || {
      start_time: dayjs().tz(),
      end_time: dayjs().tz(),
    }
  );
};
export const subscription_frequency = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];
export const subscription_trial_unit = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

export const auth_period_unit = [
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
];

export const amountFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export interface IFilterOptions {
  label: string;
  value: string;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

export function iterateHeadCellKeys(
  headcell:
    | HeadCellTransactions[]
    | HeadCellTransactionLog[]
    | HeadCellSubscription[]
    | HeadCellOrders[]
    | HeadCellCarts[]
    | HeadCellCustomers[]
    | HeadCellSubscriptionEvents[]
    | HeadCellAdmin[],
): IFilterOptions[] {
  const filterValues: IFilterOptions[] = [];

  headcell.forEach(value => {
    if (value?.showInSearch === true) {
      filterValues.push({
        label: value.label,
        value: value.id,
        searchFiedtType: value?.searchFiedtType || 'input',
        searchFieldOptions: value?.searchFieldOptions || [],
        ...(value?.inputType && { inputType: value?.inputType }),
      });
    }
  });

  return filterValues;
}

export function downloadFile(
  data: DataObject[],
  headers: string[],
  filename = 'data',
): void {
  const csvData = convertToCSV(data, headers);

  const blob = new Blob(['\ufeff' + csvData], {
    type: 'text/csv;charset=utf-8;',
  });
  const dwldLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser =
    navigator.userAgent.indexOf('Safari') !== -1 &&
    navigator.userAgent.indexOf('Chrome') === -1;
  if (isSafariBrowser) {
    dwldLink.setAttribute('target', '_blank');
  }
  dwldLink.setAttribute('href', url);
  dwldLink.setAttribute('download', filename + '.csv');
  dwldLink.style.visibility = 'hidden';
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

function convertToCSV(objArray: DataObject[], headerList: string[]): string {
  const row = headerList.join(',') + '\r\n';
  let str = row;
  for (let i = 0; i < objArray.length; i++) {
    let line = '';
    for (const index in headerList) {
      const head = headerList[index];
      line += objArray[i][head] + ',';
    }
    str += line.slice(0, -1) + '\r\n';
  }
  return str;
}

export function parseCSV(csvString: string): {
  headers: string[];
  data: DataObject[];
} {
  const lines = csvString.split('\n');
  const headers = lines[0].split(',');
  const data = lines.slice(1).map(line => {
    const values = line.split(',');
    const obj: DataObject = {};
    headers.forEach((header, index) => {
      obj[header] = values[index] ? values[index] : '-';
    });
    return obj;
  });
  return { headers, data };
}

export async function downloadCsv(csvString: string, fileName: string) {
  const { headers, data } = parseCSV(csvString);
  downloadFile(data, headers, fileName);
}

export function UsDollarFormatter(value: number): string {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

export function demicalFormatter(percentage: string): string {
  return Number(percentage?.split('.')?.[1])
    ? percentage
    : percentage?.split('.')?.[0];
}

type RestrictedType = {
  restrictedPages?: string[];
  enableForSuperAdmins?: string[];
  pagesAccessibleByEmail?: {
    [key: string]: string[];
  };
};
type FeatureToggles = {
  [key: string]: RestrictedType;
};

const checkEmailPermission = (
  environmentConfig: RestrictedType,
  email: string,
  featureName: string,
) => {
  if (
    environmentConfig.pagesAccessibleByEmail &&
    email &&
    environmentConfig.pagesAccessibleByEmail?.[featureName]?.length
  ) {
    return environmentConfig.pagesAccessibleByEmail[featureName]?.includes(
      email,
    );
  }
};

export const isFeatureEnabled = (
  featureName: string,
  allowPermission?: string,
) => {
  const superAdmin = store.getState().permission.superAdmin;

  const { email } = decodeUser(localStorage.getItem('refresh_token') as string);
  const environmentConfig = (featureToggles as FeatureToggles)[
    currentEnvironment
  ];

  // Check if the featureToggles or environmentConfig is empty
  if (_.isEmpty(environmentConfig)) {
    if (allowPermission !== undefined) {
      return allowPermission;
    }
    return false;
  }

  const emailPermission = checkEmailPermission(
    environmentConfig,
    email,
    featureName,
  );

  // If the feature is enabled for super admins, return true for superAdmin or based on email permission
  if (environmentConfig?.enableForSuperAdmins?.includes(featureName)) {
    return superAdmin || !!emailPermission;
  }

  // Allow access based on email-specific permissions
  if (emailPermission !== undefined) {
    return emailPermission;
  }

  // Deny access if the feature is in restricted pages
  if (environmentConfig?.restrictedPages?.includes(featureName)) {
    return false;
  }

  // Return allowPermission or true as a fallback
  return allowPermission !== undefined ? allowPermission : true;
};

function getMonthNameFromNumber(
  monthNumber: number,
  fullMonthName: boolean = false,
): string {
  const date = new Date(2024, monthNumber - 1); // Create a date object with the given month
  const options: Intl.DateTimeFormatOptions = {
    month: fullMonthName ? 'long' : 'short',
  };
  return date.toLocaleString('default', options);
}

export const getDateFormat = (timezone: string) => {
  if (timezone?.toLowerCase()?.includes('america')) {
    return 'MM-DD-YYYY';
  } else {
    return 'DD-MM-YYYY';
  }
};
export const sortByDateAscending = <T>(
  data: T[],
  dateKey: keyof T,
  orderBy?: string,
) =>
  data.sort((a, b) => {
    const dateA = new Date(a[dateKey] as string | number | Date).getTime();
    const dateB = new Date(b[dateKey] as string | number | Date).getTime();
    if (orderBy === 'DESC') {
      return dateB - dateA;
    } else {
      return dateA - dateB; // By default, sort by ascending
    }
  });
