import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  commonError,
  getAggregatedTransactionEP,
  getDisputeCodeEP,
  getLifeTimSubscriptionEP,
  getRefundAmountEP,
  getUniqueApprovalEP,
} from 'services/constants';
import { IDashBoardBody } from 'interface/dashboardNewIterface';

export const getLifeTimeSubscriptions = async (body: IDashBoardBody) => {
  try {
    const response = await baseService.post(getLifeTimSubscriptionEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getAggregatedTransactions = async (body: IDashBoardBody) => {
  try {
    const response = await baseService.post(getAggregatedTransactionEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getUniqueApprovals = async (body: IDashBoardBody) => {
  try {
    const response = await baseService.post(getUniqueApprovalEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getRefundAmountStats = async (body: IDashBoardBody) => {
  try {
    const response = await baseService.post(getRefundAmountEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getTransactionsDisputeCodes = async (body: IDashBoardBody) => {
  try {
    const response = await baseService.post(getDisputeCodeEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};
