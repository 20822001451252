import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Button } from 'shared-components';
import 'components/transactions/transactionFilter/filterForm/style.scss';
import { FC } from 'react';
import { useState } from 'react';
import { ControlledDatePicker } from 'components/common/datePicker';

import { ControlledMultiSelect } from 'components/common/multiSelect';
import { defaultTransactionFilterValues } from 'components/transactions';

import { IDefaultOption } from 'interface/customerInterface';
import { dateRange, dateRangeOptions } from 'components/dashboard/constant';
import { getDateFilter } from 'utils/helper';
import dayjs from 'dayjs';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { ITransactionLogFormFilter } from 'interface/transactionLog';

interface IProps {
  sideFormFilter: ITransactionLogFormFilter;
  filterSubmission: (_data: ITransactionLogFormFilter) => void;
  resetSelected: () => void;
}

const FilterForm: FC<IProps> = ({
  sideFormFilter,
  filterSubmission,
  resetSelected,
}) => {
  const [isCustomRange, setCustomRange] = useState<boolean>(
    !!sideFormFilter.DateRange,
  );
  const methods = useForm<ITransactionLogFormFilter>({
    defaultValues: sideFormFilter,
  });
  const { handleSubmit, control, reset, setValue, watch } = methods;

  const onSubmit = (data: ITransactionLogFormFilter) => {
    filterSubmission(data);
    resetSelected();
  };

  const transactionStatus: IDefaultOption[] = [
    { label: 'Captured', value: 'captured' },
    { label: 'Fail Authorization', value: 'fail_authorization' },
    { label: 'Voided', value: 'voided' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Partial Refund', value: 'partial_refund' },
    { label: 'Chargeback', value: 'chargeback' },
    { label: 'Fail Capture', value: 'fail_capture' },
    { label: 'Authorized', value: 'authorized' },
  ];
  const triggerBy: IDefaultOption[] = [
    { label: 'System', value: 'system' },
    { label: 'Support', value: 'support' },
    { label: 'Customer', value: 'customer' },
  ];

  return (
    <div className="filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <FormProvider {...methods}>
          <div className="date-range-or-occurence">
            <Controller
              name="DateRangeOrOccurence"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutoCompleteSelect
                  options={[
                    { label: 'Transaction Date', value: 'normal' },
                    { label: 'Occurence Date', value: 'occurence' },
                  ]}
                  labelKey="label"
                  onChange={val => onChange(val?.value)}
                  value={
                    [
                      { label: 'Date Range', value: 'dateRange' },
                      { label: 'Occurence Time', value: 'occurenceTime' },
                    ].find(type => type.value === value) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                  label="Filter By"
                />
              )}
            />
          </div>

          <Controller
            name="DateRange"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const handleChange = (e: string) => {
                if (e !== 'Custom Range') {
                  const { start_time, end_time } = getDateFilter(e);
                  setValue('StartTime', start_time);
                  setValue('EndTime', end_time);
                } else {
                  setValue('StartTime', null);
                  setValue('EndTime', null);
                }
                setCustomRange(true);
                onChange(e);
              };
              return (
                <AutoCompleteSelect
                  options={dateRangeOptions}
                  labelKey="label"
                  onChange={val => handleChange(val?.value)}
                  value={
                    dateRangeOptions?.find(type => type.value === value) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                  label="Date Range"
                />
              );
            }}
          />
          {isCustomRange && (
            <>
              <ControlledDatePicker
                maxDate={dayjs(watch('EndTime')).tz()}
                disableFuture={true}
                name="StartTime"
                label="Start Date"
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().startOf('day') : value;
                }}
              />
              <ControlledDatePicker
                name="EndTime"
                label="End Date"
                minDate={dayjs(watch('StartTime')).tz()}
                disableFuture={true}
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().endOf('day') : value;
                }}
              />{' '}
            </>
          )}

          <ControlledMultiSelect<IDefaultOption>
            name="EventTypes"
            options={transactionStatus}
            labelKey="label"
            valueKey="value"
            label="Transaction Status"
            placeholder="Select Transaction Status"
            limitTags={1}
          />
          <ControlledMultiSelect<IDefaultOption>
            name="Triggers"
            options={triggerBy}
            labelKey="label"
            valueKey="value"
            label="Trigger By"
            placeholder="Select Trigger By"
            limitTags={1}
          />

          <div className="button_wrapper">
            <Button
              className="transaction_for_submit"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="transaction_for_submit clear_button"
              onClick={() => {
                reset(defaultTransactionFilterValues);
              }}
              label="Clear Filter"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
export default FilterForm;
