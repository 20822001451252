import { useForm, Controller } from 'react-hook-form';
import TextField from 'components/common/textField';
import { Button, ToggleSwitch } from 'shared-components';
import 'components/abandonedCarts/abandonedCartFilter/filterForm/style.scss';
import MultiSelect from 'components/common/multiSelect';
import { IDefaultOption } from 'interface/customerInterface';
import { FC } from 'react';
import { defaultCartFilterValues } from 'components/abandonedCarts';
import { MultipleInputTag } from 'components/common/multiInput';
import { emailRegex, trafficChannelData } from 'utils/constants';
import { ICartsSideFilter } from 'interface/processorAssignment';
import DatePicker from 'components/common/datePicker';
import dayjs from 'dayjs';

interface IProps {
  sideFormFilter: ICartsSideFilter;
  filterSubmission: (_data: ICartsSideFilter) => void;
}

const FilterForm: FC<IProps> = ({ sideFormFilter, filterSubmission }) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<ICartsSideFilter>({
    defaultValues: sideFormFilter,
  });

  const onSubmit = (data: ICartsSideFilter) => {
    filterSubmission(data);
  };

  const subscriptionStatus: IDefaultOption[] = [
    { label: 'Completed', value: 'completed' },
    { label: 'Pending', value: 'pending' },
    { label: 'Failed', value: 'failed' },
    { label: 'Voided', value: 'voided' },
    { label: 'Refunded', value: 'refunded' },
  ];

  return (
    <div className="order-filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="Emails"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value || []}
                label="Customer Emails"
                isLowerCase={true}
                placeholder="Type Emails"
                validation={[
                  {
                    validationRule: emailRegex,
                    validationMessage: 'Enter a valid email address',
                  },
                ]}
                setError={(value: string) => {
                  setError('Emails', {
                    type: 'custom',
                    message: value,
                  });
                }}
                onChange={value => {
                  onChange(value);
                }}
                errorString={errors?.Emails?.message}
              />
            );
          }}
        />

        <Controller
          name="Status"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={subscriptionStatus.filter(each =>
                  value?.some(val => val === each.value),
                )}
                options={subscriptionStatus}
                labelKey="label"
                valueKey="value"
                label="Status"
                placeholder="Select Payment Status"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue?.map(each => each.value));
                }}
              />
            );
          }}
        />
        <Controller
          name="TrafficChannels"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={trafficChannelData?.filter(each =>
                  value?.some(val => val === each.value),
                )}
                options={trafficChannelData}
                labelKey="label"
                valueKey="value"
                label="Traffic Channel"
                placeholder="Select Traffic Channel"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue?.map(each => each.value));
                }}
                error={errors?.TrafficChannels?.message}
              />
            );
          }}
        />
        <div className="common_box">
          <p className="common_input_label">Order Date</p>
          <div className="flexContainer duel-datepicker">
            <Controller
              name="StartDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    label=""
                    maxDate={dayjs(watch('EndDate')).tz()}
                    disableFuture={true}
                    error={errors?.StartDate?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="EndDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    label=""
                    minDate={dayjs(watch('StartDate')).tz()}
                    disableFuture={true}
                    error={errors?.EndDate?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="common_box">
          <p className="common_input_label">Modified Date</p>
          <div className="flexContainer duel-datepicker">
            <Controller
              name="StartModifiedDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    label=""
                    maxDate={dayjs(watch('EndModifiedDate')).tz()}
                    disableFuture={true}
                    error={errors?.StartModifiedDate?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="EndModifiedDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    label=""
                    minDate={dayjs(watch('StartModifiedDate')).tz()}
                    disableFuture={true}
                    error={errors?.EndModifiedDate?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <p className="common_input_label">Include No contact?</p>
          <Controller
            name="IncludeNoContactInfo"
            control={control}
            render={({ field: { ref, value, ...rest } }) => {
              return (
                <ToggleSwitch
                  {...rest}
                  checked={value}
                  className="filter_form_input"
                  ref={ref}
                />
              );
            }}
          />
        </div>
        <div className="direct_sale_box">
          <p className="common_input_label">Total</p>
          <div className="flexContainer">
            <Controller
              name="MinTotalAmount"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    isPrefixText="$"
                    className="filter_form_input"
                    placeholder="Min value"
                    fixedSize="md"
                    ref={ref}
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }></TextField>
                );
              }}
            />
            <Controller
              name="MaxTotalAmount"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    isPrefixText="$"
                    className="filter_form_input"
                    placeholder="Max Value"
                    fixedSize="md"
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }
                    ref={ref}></TextField>
                );
              }}
            />
          </div>
        </div>
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              reset(defaultCartFilterValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
