import { DoughnutBarChart } from 'components/common/chart/barChart';
import { LifeTimetransactionStats } from 'interface/dashboardNewIterface';
import { FC, useMemo } from 'react';
import { Loader } from 'shared-components';
import { getPercentageByValue } from 'utils/helper';

interface IProps {
  cardBreakDownStats: LifeTimetransactionStats[];
  loading: boolean;
}
const CardBreakDown: FC<IProps> = ({ cardBreakDownStats, loading }) => {
  const cardBreakDownData = useMemo(() => {
    let visa = 0;
    let masterCard = 0;
    let others = 0;
    let discover = 0;
    let amex = 0;
    cardBreakDownStats?.forEach(stat => {
      if (stat.CardBrand === 'visa') {
        visa += stat?.Total;
        return;
      }
      if (stat.CardBrand === 'mastercard') {
        masterCard += stat?.Total;
        return;
      }
      if (stat.CardBrand === 'disc' || stat.CardBrand === 'dc') {
        discover += stat?.Total;
        return;
      }
      if (stat.CardBrand === 'amex') {
        amex += stat?.Total;
        return;
      }
      others += stat?.Total;
    });
    const total = visa + masterCard + others + discover + amex;
    return {
      legend: [
        { name: 'Master Card', color: '#F90182', value: masterCard },
        { name: 'VISA', color: '#6AD2FF', value: visa },
        { name: 'Discover', color: '#54f337', value: discover },
        { name: 'Amex', color: '#eb5432', value: amex },
        { name: 'Others', color: '#C237F3', value: others },
      ],
      chart: {
        labels: ['Master Card', 'VISA', 'Discover', 'Amex', 'Others'],
        datasets: [
          {
            data: [
              getPercentageByValue(masterCard, total),
              getPercentageByValue(visa, total),
              getPercentageByValue(discover, total),
              getPercentageByValue(amex, total),
              getPercentageByValue(others, total),
            ],
            count: [masterCard, visa, discover, amex, others],
            fill: false,
            backgroundColor: [
              '#F90182',
              '#6AD2FF',
              '#54f337',
              '#eb5432',
              '#C237F3',
            ],
            borderColor: [
              '#F90182',
              '#6AD2FF',
              '#54f337',
              '#eb5432',
              '#C237F3',
            ],
            borderWidth: 1,
          },
        ],
      },
      total,
    };
  }, [cardBreakDownStats]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Card Breakdown</p>
      </div>
      <div className="footer ">
        {cardBreakDownData.legend.map(item => {
          return (
            <div
              className="flex flex-col justify-center align-middle gap-0.5"
              key={item.name}>
              <div className="footer_details">
                {' '}
                <div
                  key={item.name}
                  className="indicator"
                  style={{ backgroundColor: item.color }}></div>
                <span className="footer_text">{item.name}</span>
              </div>
              <div className="transaction_values">{item.value}</div>
            </div>
          );
        })}
      </div>
      <div className="chart_wrapper">
        {cardBreakDownData?.chart?.datasets?.[0]?.count?.every(
          item => item === 0,
        ) ? (
          <div className="no_data">No Data</div>
        ) : (
          <DoughnutBarChart
            data={cardBreakDownData?.chart}
            className="chart"
            isOnlyCount={true}
          />
        )}
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default CardBreakDown;
