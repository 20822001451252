import { useMemo, FC, useState } from 'react';
import { DoughnutBarChart } from 'components/common/chart/barChart';
import { getPercentageByValue } from 'utils/helper';

import { ChartDataset } from 'chart.js';
import { UnionToIntersection } from 'chart.js/dist/types/utils';
import ButtonGroup from 'components/common/ButtonGroup';
import { TransactionStats } from 'interface/dashboardInterface';
import { Loader } from 'shared-components';

interface IProps {
  transactionDisputesCode: TransactionStats[];
  loading: boolean;
}

export interface IDatasets extends UnionToIntersection<ChartDataset<'bar'>> {
  data: number[];
  count: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

const cardTypesFilter = {
  all: 'All',
  visa: 'VISA',
  masterCard: 'Master Card',
};

const filterButtons = Object.values(cardTypesFilter).map(types => ({
  name: types,
}));

const CoverageHealth: FC<IProps> = ({ transactionDisputesCode, loading }) => {
  const [cardType, setCardType] = useState(cardTypesFilter.all);

  const coverageHealthData = useMemo(() => {
    let countOfCb = 0;
    let countOfRdr = 0;
    let countOfEthoca = 0;
    let countOftotalDispute = 0;
    let total = 0;
    transactionDisputesCode?.length > 0 &&
      transactionDisputesCode?.forEach(stat => {
        if (
          (cardType === cardTypesFilter?.visa && stat?.CardBrand !== 'visa') ||
          (cardType === cardTypesFilter?.masterCard &&
            stat?.CardBrand !== 'mastercard')
        )
          return;
        total += stat?.Count;

        if (stat?.DisputeAlerts?.includes('Chargeback')) {
          countOfCb += stat?.Count;
          countOftotalDispute += stat?.Count;
          return;
        }
        if (stat?.DisputeAlerts?.includes('RDR')) {
          countOfRdr += stat?.Count;
          countOftotalDispute += stat?.Count;
          return;
        }
        if (stat?.DisputeAlerts?.includes('Ethoca')) {
          countOfEthoca += stat?.Count;
          countOftotalDispute += stat?.Count;
          return;
        }
        if (stat?.DisputeAlerts?.some(alert => alert)) {
          countOftotalDispute += stat?.Count;
        }
      });

    return {
      legend: [
        {
          name: `Overall Dispute`,
          color: '#F90182',
          value: countOftotalDispute,
        },
        { name: `CB`, color: '#6AD2FF', value: countOfCb },
        { name: `RDR`, color: '#C237F3', value: countOfRdr },
        { name: `Ethoca`, color: '#f36337', value: countOfEthoca },
      ],
      chart: {
        labels: [`Overall Dispute`, `CB`, `RDR`, `Ethoca`],

        datasets: [
          {
            data: [
              getPercentageByValue(countOftotalDispute, total),
              getPercentageByValue(countOfCb, total),
              getPercentageByValue(countOfRdr, total),
              getPercentageByValue(countOfEthoca, total),
            ],
            minBarLength: 2,
            count: [countOftotalDispute, countOfCb, countOfRdr, countOfEthoca],
            fill: false,
            backgroundColor: ['#F90182', '#6AD2FF', '#C237F3', '#f36337'],
            borderColor: ['#F90182', '#6AD2FF', '#C237F3', '#f36337'],
            borderWidth: 1,
          },
        ],
      },
    };
  }, [transactionDisputesCode, cardType]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Coverage Health</p>
        <ButtonGroup
          active={cardType}
          setActive={setCardType}
          buttons={filterButtons}
        />
      </div>
      <div className="footer">
        {coverageHealthData.legend.map((item, index) => {
          return (
            <div
              className="flex flex-col justify-center align-middle gap-0.5"
              key={item?.name}>
              <div className="footer_details">
                {' '}
                <div
                  key={item?.name}
                  className="indicator"
                  style={{ backgroundColor: item?.color }}></div>
                <span className="footer_text">{item?.name}</span>
              </div>
              <div className="transaction_values">
                {item?.value}
                {` (${coverageHealthData?.chart?.datasets?.[0]?.data?.[index]}%)`}
              </div>
            </div>
          );
        })}
      </div>
      <div className="chart_wrapper items-end flex justify-center">
        {coverageHealthData?.chart?.datasets?.[0]?.count?.every(
          item => item === 0,
        ) ? (
          <div className="no_data">No Data</div>
        ) : (
          <DoughnutBarChart
            data={coverageHealthData?.chart}
            className="chart"
          />
        )}
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default CoverageHealth;
