import { useEffect, useMemo, useState } from 'react';
import StatsCards from './StatsCards';
import './style.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import CustomDatePicker from 'components/common/customDatePicker';
import {
  IDashBoardBody,
  LifetimeSubscriptionStats,
  LifeTimetransactionStats,
  RefundAmountStats,
  UniqueAprrovalStats,
} from 'interface/dashboardNewIterface';
import { useAppSelector } from 'hooks/reduxHooks';
import {
  getAggregatedTransactions,
  getUniqueApprovals,
  getLifeTimeSubscriptions,
  getRefundAmountStats,
} from 'services/dashboardNewService';
import { groupBy } from 'lodash';
import { groupPoints } from 'utils/helper';
import SalesRevenue from './SaleRevenue';
import {
  ISubscriptionStats,
  ITransactionStats,
} from 'interface/dashboardInterface';
import {
  getSubscriptionStats,
  getTransactionStats,
} from 'services/dashboardService';
import NetSubscribers from './NetSubscribers';
import UniqueApprovalRatio from './UniqueApprovalRatio';
import CardBreakDown from './CardBreakDown';
import TransactionStatus from './TransactionStatus';
import CoverageHealth from './CoverageHealth';
import ChargeBackSummary from './ChargeBackSummary';

export interface IDashboardFilter {
  start_time: Dayjs;
  end_time: Dayjs;
  date_range: string;
  toggle: boolean;
  start_month: Dayjs;
  end_month: Dayjs;
}

const emptyStats = {
  transaction: {
    Amount: 0,
    RefundedAmount: 0,
    Count: 0,
    SourceCount: 0,
    ChargeType: '',
    CardBrand: '',
    Status: '',
    DisputeAlerts: [],
    CycleNumber: 0,
    Date: '',
  },
  lifetimeSubscriptionStats: {
    Approved: 0,
    Date: '',
    Kind: '',
    Losses: 0,
    Revenue: 0,
  },
  subscription: {
    NewSubscriptions: 0,
    CanceledSubscriptions: 0,
    SalvagingSubscriptions: 0,
    RecoveredSubscriptions: 0,
    RenewedSubscriptions: 0,
    Date: '',
  },
};

export default function Dashboard2() {
  const [loading, setLoading] = useState({
    saleRevenueLoader: false,
    netSubscriberLoader: false,
    uniqueApprovalLoader: false,
    chargebackLoader: false,
    transactionStatusLoader: false,
    aggregatedTransactionLoader: false,
    transactioDisputesCodeLoader: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionDisputesCodeData, setTransactionDisputesCodeData] =
    useState<ITransactionStats | null>(null);
  const [lifetimeSubscriptionStats, setLifeTimeSubscriptionStats] =
    useState<LifetimeSubscriptionStats | null>(null);
  const [subscriptionStats, setSubscriptionStats] =
    useState<ISubscriptionStats | null>(null);
  const [aggregatedTransactionStats, setAggregatedTransactionStats] = useState<
    LifeTimetransactionStats[]
  >([]);
  const [uniqueApprovalStats, setUniqueApprovalStats] = useState<
    UniqueAprrovalStats[]
  >([]);
  const [refundAmountStats, setRefundAmountStats] =
    useState<null | RefundAmountStats>(null);
  const default_start_time = dayjs().tz().startOf('day');
  const default_end_time = dayjs().tz().endOf('day');
  const [filter, setFilter] = useState<IDashboardFilter>({
    start_time: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : default_start_time,
    end_time: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : default_end_time,
    date_range: searchParams.get('date_range') || 'Today',
    end_month: dayjs().tz().endOf('month'),
    start_month: dayjs().tz().startOf('month'),
    toggle: Boolean(searchParams.get('toggle')) || false,
  });
  const storeIds = useAppSelector(state => state.storeIds.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr.length ? arr : null;
  });

  const { timeZone } = useAppSelector(state => state.pathConfig);
  const handleFilter = (
    filterProptery: keyof typeof filter,
    value: unknown,
  ) => {
    setFilter(pre => ({ ...pre, [filterProptery]: value }));
  };

  const groupedTransactionStats = useMemo(() => {
    if (
      !lifetimeSubscriptionStats ||
      !lifetimeSubscriptionStats?.Data?.length
    ) {
      return [];
    }
    const grouped = groupBy(lifetimeSubscriptionStats.Data, 'Date');
    return groupPoints(
      grouped,
      emptyStats?.lifetimeSubscriptionStats,
      true,
      lifetimeSubscriptionStats?.BinWidth,
    );
  }, [lifetimeSubscriptionStats]);

  const groupedTransactionStatsBreak = useMemo(() => {
    if (
      !transactionDisputesCodeData ||
      !transactionDisputesCodeData?.Stats?.length
    ) {
      return [];
    }
    const grouped = groupBy(transactionDisputesCodeData.Stats, 'Date');
    return groupPoints(grouped, emptyStats.transaction);
  }, [transactionDisputesCodeData]);

  const subStats = async (payload: IDashBoardBody) => {
    try {
      setLoading(prev => ({ ...prev, netSubscriberLoader: true }));
      const response = await getSubscriptionStats(payload);

      if (response?.status === 200) {
        if (response?.data) {
          setSubscriptionStats(response.data);
        } else {
          setSubscriptionStats(null);
        }
      }
    } catch (error) {
      console.error('Error fetching subscription stats:', error);
    } finally {
      setLoading(prev => ({ ...prev, netSubscriberLoader: false }));
    }
  };

  const lifeTimeSubcStats = async (payload: IDashBoardBody) => {
    try {
      setLoading(prev => ({ ...prev, saleRevenueLoader: true }));
      const response = await getLifeTimeSubscriptions(payload);

      if (response?.status === 200) {
        if (response?.data) {
          setLifeTimeSubscriptionStats(response.data);
        } else {
          setLifeTimeSubscriptionStats(null);
        }
      }
    } catch (error) {
      console.error('Error fetching lifetime subscriptions:', error);
    } finally {
      setLoading(prev => ({ ...prev, saleRevenueLoader: false }));
    }
  };

  const aggregatedTransc = async (payload: IDashBoardBody) => {
    try {
      setLoading(prev => ({
        ...prev,
        aggregatedTransactionLoader: true,
        transactionStatusLoader: true,
      }));
      const response = await getAggregatedTransactions(payload);

      if (response?.status === 200) {
        if (response?.data) {
          setAggregatedTransactionStats(response.data.Result);
        } else {
          setAggregatedTransactionStats([]);
        }
      }
    } catch (error) {
      console.error('Error fetching aggregated transactions:', error);
    } finally {
      setLoading(prev => ({
        ...prev,
        aggregatedTransactionLoader: false,
        transactionStatusLoader: false,
      }));
    }
  };

  const uniqueApprove = async (payload: IDashBoardBody) => {
    try {
      setLoading(prev => ({ ...prev, uniqueApprovalLoader: true }));
      const response = await getUniqueApprovals(payload);

      if (response?.status === 200) {
        if (response?.data) {
          setUniqueApprovalStats(response.data.Result);
        } else {
          setUniqueApprovalStats([]);
        }
      }
    } catch (error) {
      console.error('Error fetching unique approvals:', error);
    } finally {
      setLoading(prev => ({ ...prev, uniqueApprovalLoader: false }));
    }
  };

  const getRefundStats = async (payload: IDashBoardBody) => {
    const response = await getRefundAmountStats(payload);
    if (response?.status === 200 && response?.data) {
      setRefundAmountStats(response.data);
    } else {
      setRefundAmountStats(null);
    }
  };

  const transactionDisput = async (payload: IDashBoardBody) => {
    try {
      setLoading(prev => ({
        ...prev,
        transactioDisputesCodeLoader: true,
        chargebackLoader: true,
      }));
      const response = await getTransactionStats(payload);

      if (response?.status === 200) {
        if (response?.data) {
          setTransactionDisputesCodeData(response.data);
        } else {
          setTransactionDisputesCodeData(null);
        }
      }
    } catch (error) {
      console.error('Error fetching transaction disputes:', error);
    } finally {
      setLoading(prev => ({
        ...prev,
        transactioDisputesCodeLoader: false,
        chargebackLoader: false,
      }));
    }
  };
  const getDashboardstats = async () => {
    const payload: IDashBoardBody = {
      EndTime: filter.end_time.add(1, 'day').startOf('day').tz().format(),
      StartTime: filter.start_time.tz().format(),
      StoreIDs: storeIds.length ? storeIds.map(val => val.ID) : null,
      StorefrontIDs: storefrontIds,
    };

    setSearchParams(
      {
        start_time: dayjs(filter.start_time).tz().format() || '',
        end_time: dayjs(filter.end_time).tz().format() || '',
        date_range: filter.date_range,
      },
      { replace: true },
    );

    subStats(payload);
    lifeTimeSubcStats(payload);
    aggregatedTransc(payload);
    uniqueApprove(payload);
    getRefundStats(payload);
    transactionDisput(payload);
  };

  useEffect(() => {
    getDashboardstats();
  }, [storeIds, filter.end_time, filter.start_time, timeZone]);

  const groupedSubscriptionStats = useMemo(() => {
    if (!subscriptionStats || !subscriptionStats?.Stats?.length) {
      return [];
    }
    const grouped = groupBy(subscriptionStats.Stats, 'Date');
    return groupPoints(
      grouped,
      emptyStats.subscription,
      true,
      subscriptionStats.BinWidth,
    );
  }, [subscriptionStats]);

  return (
    <div className="new_dashboard">
      <div className="dashboard_filter row-full">
        <div className="dual-date-container">
          <div className="common_label_text">Date Range</div>
          <CustomDatePicker
            selectedRange={{
              startDate: filter?.start_time,
              endDate: filter?.end_time,
            }}
            dateValues={range => {
              if (range.startDate && range.endDate) {
                handleFilter('start_time', range?.startDate);
                handleFilter('end_time', range?.endDate);
              }
            }}
            maxDate={dayjs().tz().toDate()}
          />
        </div>
      </div>

      <StatsCards
        uniqueTransactionStats={uniqueApprovalStats}
        subscriptionStats={subscriptionStats}
        refundAmountStats={refundAmountStats}
        filter={filter}
      />
      {filter.toggle ? (
        <div></div>
      ) : (
        <div className="line_chart_container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <SalesRevenue
              groupedTransactionStats={groupedTransactionStats}
              loader={loading.saleRevenueLoader}
            />
            <NetSubscribers
              groupedSubscriptionStats={groupedSubscriptionStats}
              loading={loading.netSubscriberLoader}
            />
            <UniqueApprovalRatio
              uniqueApprovalStats={uniqueApprovalStats}
              loading={loading.uniqueApprovalLoader}
            />
            <ChargeBackSummary
              groupedTransactionStats={groupedTransactionStatsBreak}
              loading={loading.chargebackLoader}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <TransactionStatus
              aggregatedTransactionStats={aggregatedTransactionStats}
              loading={loading.transactionStatusLoader}
            />
            <CardBreakDown
              cardBreakDownStats={aggregatedTransactionStats}
              loading={loading.aggregatedTransactionLoader}
            />
            <CoverageHealth
              transactionDisputesCode={
                transactionDisputesCodeData?.Stats
                  ? transactionDisputesCodeData?.Stats
                  : []
              }
              loading={loading.transactioDisputesCodeLoader}
            />
          </div>
        </div>
      )}
    </div>
  );
}
