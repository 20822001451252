import { useMemo, FC } from 'react';
import { chargeType } from 'components/dashboard/constant';
import { UniqueAprrovalStats } from 'interface/dashboardNewIterface';
import { HorizontalBarChart } from 'components/common/chart/barChart';
import { getPercentageByValue } from 'utils/helper';

import { ChartDataset } from 'chart.js';
import { UnionToIntersection } from 'chart.js/dist/types/utils';
import { Loader } from 'shared-components';

interface IProps {
  uniqueApprovalStats: UniqueAprrovalStats[];
  loading: boolean;
}

export interface IDatasets extends UnionToIntersection<ChartDataset<'bar'>> {
  data: number[];
  count: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

const UniqueApprovalRatio: FC<IProps> = ({ uniqueApprovalStats, loading }) => {
  const salesRevenueData = useMemo(() => {
    let straightSaleApproved = 0;
    let straightSaleApprovedTotal = 0;
    let initialSubscription = 0;
    let recurringSubcription = 0;
    let initialSubscriptionTotal = 0;
    let recurringSubcriptionTotal = 0;
    let upsellTotal = 0;
    let upsellApproved = 0;
    let allTransactionTotal = 0;
    uniqueApprovalStats?.forEach(stat => {
      allTransactionTotal += stat.Count;
      if (stat.ChargeType === chargeType.subscription) {
        if (stat.Initial) {
          initialSubscriptionTotal += stat.Count;
          initialSubscription += stat.Count;
          if (stat.Declined) initialSubscription -= stat.Count;
          return;
        }
        recurringSubcription += stat.Count;
        recurringSubcriptionTotal += stat.Count;
        if (stat.Declined) recurringSubcription -= stat.Count;
        return;
      } else if (stat.ChargeType === chargeType.upsell) {
        upsellApproved += stat.Count;
        upsellTotal += stat.Count;
        if (stat.Declined) {
          upsellApproved -= stat.Count;
        }
        return;
      }
      straightSaleApproved += stat.Count;
      straightSaleApprovedTotal += stat.Count;
      if (stat.Declined) straightSaleApproved -= stat.Count;
    });
    const totalofApproved =
      straightSaleApproved +
      initialSubscription +
      recurringSubcription +
      upsellApproved;

    return {
      legend: [
        { name: 'DirectSale', color: '#F90182' },
        { name: 'Initial Subscription', color: '#6AD2FF' },
        { name: 'Recurring Subscription', color: '#C237F3' },
        { name: 'UpSell', color: '#1243d8' },
      ],
      chart: {
        labels: [
          'Direct Sale',
          'Initial Subscription',
          'Recurring Subscription',
          'UpSell',
        ],
        datasets: [
          {
            data: [
              getPercentageByValue(
                straightSaleApproved,
                straightSaleApprovedTotal,
              ),
              getPercentageByValue(
                initialSubscription,
                initialSubscriptionTotal,
              ),
              getPercentageByValue(
                recurringSubcription,
                recurringSubcriptionTotal,
              ),
              getPercentageByValue(upsellApproved, upsellTotal),
            ],
            count: [
              straightSaleApproved,
              initialSubscription,
              recurringSubcription,
              upsellApproved,
            ],
            backgroundColor: ['#F90182', '#6AD2FF', '#C237F3', '#1243d8'],
            borderColor: ['#F90182', '#6AD2FF', '#C237F3', '#1243d8'],
            borderWidth: 1,
          },
        ],
      },
      totalofApproved,
      allTransactionTotal,
      subscriptionTotal: initialSubscriptionTotal + recurringSubcriptionTotal,
      subscription: initialSubscription + recurringSubcription,
      initialSubscription,
      initialSubscriptionTotal,
      recurringSubcription,
      recurringSubcriptionTotal,
      straightSaleApprovedTotal,
      straightSaleApproved,
      upsellTotal,
      upsellApproved,
    };
  }, [uniqueApprovalStats]);

  const formetedData = useMemo(() => {
    return [
      {
        name: 'All Transactions',
        value: `${getPercentageByValue(
          salesRevenueData.totalofApproved,
          salesRevenueData.allTransactionTotal,
        )}
            % (${salesRevenueData?.totalofApproved}/${salesRevenueData?.allTransactionTotal})`,
        color: '',
      },
      {
        name: 'Direct Sale',
        value: `${getPercentageByValue(
          salesRevenueData.straightSaleApproved,
          salesRevenueData.straightSaleApprovedTotal,
        )}
            % (${salesRevenueData?.straightSaleApproved}/${salesRevenueData.straightSaleApprovedTotal})`,
        color: '#F90182',
      },
      {
        name: 'Initial Subscription',
        value: `${getPercentageByValue(
          salesRevenueData.initialSubscription,
          salesRevenueData.initialSubscriptionTotal,
        )}
            % (${salesRevenueData?.initialSubscription}/${salesRevenueData?.initialSubscriptionTotal})`,
        color: '#6AD2FF',
      },
      {
        name: 'Recurring Subscription',
        value: `${getPercentageByValue(
          salesRevenueData.recurringSubcription,
          salesRevenueData.recurringSubcriptionTotal,
        )}
            %(${salesRevenueData?.recurringSubcription}/${salesRevenueData?.recurringSubcriptionTotal})`,
        color: '#C237F3',
      },
      {
        name: 'UpSell',
        value: `${getPercentageByValue(
          salesRevenueData.upsellApproved,
          salesRevenueData.upsellTotal,
        )}
            % (${salesRevenueData?.upsellApproved}/${salesRevenueData?.upsellTotal})`,
        color: '#1243d8',
      },
    ];
  }, [salesRevenueData]);
  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Unique Approval Ratio</p>
      </div>
      <div className="flex flex-row gap-5 flex-wrap">
        <div className="footer">
          {formetedData.map(item => {
            return (
              <div className="flex flex-col sub_header gap-1" key={item?.name}>
                <div className="footer_details" key={item?.name}>
                  {item?.color && (
                    <div
                      key={item?.name}
                      className="indicator"
                      style={{ backgroundColor: item?.color }}></div>
                  )}
                  <span className="footer_text">{item?.name}</span>
                </div>
                <p className="sub_header_2">{item?.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="chart_wrapper pl-1 !max-h-[400px] !min-h-[250px]">
        <HorizontalBarChart data={salesRevenueData?.chart} />
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default UniqueApprovalRatio;
