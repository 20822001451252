import { FC, useEffect } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';

import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';

import { IUsersFilter } from 'interface/userInterface';
import FilterForm from './FilterForm';
import FilterAction from 'components/common/filterAction';

interface ISideFilter {
  sideFilter: IUsersFilter;
  filterSubmission: (_data: IUsersFilter) => void;
  setPage: (_page: number) => void;
}

const SideFilter: FC<ISideFilter> = ({
  sideFilter,
  filterSubmission,
  setPage,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { order } = useAppSelector(state => state.permission);
  const filterAction = [{ key: 'Filters', icon: FilterIcon }];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({
        filterSource: 'storefrontEvents',
        currentFilter: filter,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);
  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFilter={sideFilter}
            setPage={setPage}
            filterSubmission={filterSubmission}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={order}
        setSelectedFilter={setSelectedFilter}
      />
      {!!currentFilter && filterSource === 'storefrontEvents' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
